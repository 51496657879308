// --------------------------------------------------------------------------
// Modules / FAQ
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. General


// --------------------------------------------------------------------------
// 01. General
// --------------------------------------------------------------------------

.module-faq .faq__item {
  background-color: $white;
  border-bottom: 1px solid rgba($color-alpha, .25);
  @include ms-margin-bottom(2);
  transition: box-shadow .3s $easing-alpha-out;

  &:last-child {
    @include ms-margin-bottom(zero);
  }
}

.module-faq .item__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include ms-padding(0 $content-element-gutter-mobile, break-0);
  @include ms-padding(0 $content-element-gutter, break-1 to break-4);

  &:after {
    color: $color-alpha;
    @include ms-margin-left(0);
    transition: all .35s $easing-alpha-out;
  }
}

.module-faq .faq__item:not(.-no-text) .item__title {
  cursor: pointer;
}

.module-faq .faq__item.-opened .item__title:after {
  transform: rotate(90deg);
}

.module-faq .item__bodytext {
  @include ms-padding(zero $content-element-gutter-mobile, break-0);
  @include ms-padding(zero $content-element-gutter, break-1 to break-4);
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity .35s $easing-alpha-out;
}

.module-faq .faq__item.-opened .item__bodytext {
  height: auto;
  @include ms-padding($content-element-gutter-mobile, break-0);
  @include ms-padding($content-element-gutter, break-1 to break-4);
  opacity: 1;
}
