// --------------------------------------------------------------------------
// Base / Column Wrapper
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.col-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include ms-margin-bottom($spacing-vertical-gutter-mobile, break-0);
  @include ms-margin-bottom($spacing-vertical-gutter, break-1 to break-4);
}

.col-wrapper:last-child {
  @include ms-margin-bottom(zero);
}

.col-wrapper--sidebar .col-wrapper.-break-out-from-sidebar {
  position: relative;
  background-color: $white;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    @include ms-height($content-element-gutter-mobile, break-0);
    @include ms-height($content-element-gutter, break-1 to break-4);
    background: linear-gradient(to top, rgba($white, 1) 30%, rgba($white, 0));
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    @include ms-height($content-element-gutter-mobile, break-0);
    @include ms-height($content-element-gutter, break-1 to break-4);
    background: linear-gradient(to bottom, rgba($white, 1) 30%, rgba($white, 0));
    z-index: 1;
  }

  @include breakpoint(break-2) {
    // sidebarcontent = span(8) = 65.5469
    width: calc((100 / 65.5469) * 100%);
    margin-right: calc((((100 / 65.5469) * 100%) - 100%) * (-1));
  }
}

.col-wrapper--sidebar > .col-two {
  position: relative;
}

.col-wrapper--sidebar.-hide-sidebar-mobile > .col-one {
  margin: 0;
}

.col-wrapper--sidebar.-hide-sidebar-mobile > .col-two {
  display: none;

  @include breakpoint(break-2) {
    display: block;
  }
}

