// --------------------------------------------------------------------------
// Base / Typography
// --------------------------------------------------------------------------


// TABLE OF CONTENTS
// -----------------
// 01. Root
// 02. Headings
// 03. Paragraphs & Links
// 04. Lists
// 05. Various
// 06. Transform Helpers


// --------------------------------------------------------------------------
// 01. Root - html, body, ...
// --------------------------------------------------------------------------

html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  @include rootsize;
  color: $color-text-alpha;
  @include font-weight-body(regular);
  @include font-family-body;
  font-style: normal;
  line-height: $line-height-body;
}


// --------------------------------------------------------------------------
// 02. Headings
// --------------------------------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6,
.heading {
  margin: 0;
  @include font-family-heading;
  font-style: normal;
  color: $color-alpha;
}

// Heading level 1
h1,
.alpha {
  @include ms-font-size(4, break-0);
  @include ms-font-size(5, break-1 to break-2);
  @include ms-font-size(7, break-3 to break-4);
  @include font-weight-heading(bold);
  line-height: 1.2;
}

// Heading level 2
h2,
.beta {
  @include ms-font-size(3);
  @include font-weight-heading(medium);
  line-height: 1.4;
}

// Heading level 3
h3,
.gamma {
  color: $color-beta;
  @include ms-font-size(1);
  @include font-weight-heading(medium);
  line-height: 1.4;
}

// Heading level 4
h4,
.delta {
  @include ms-font-size(0);
  @include font-weight-heading(medium);
  line-height: 1.6;
}

// Heading level 5
h5,
.epsilon {
  @include ms-font-size(0);
  @include font-weight-heading(regular);
  line-height: 1.6;
}

// Heading level 6
h6,
.zeta {
  @include ms-font-size(0);
  @include font-weight-heading(regular);
  line-height: 1.6;
}


// --------------------------------------------------------------------------
// 03. Paragraphs & Links
// --------------------------------------------------------------------------

a {
  color: $color-text-beta;
  text-decoration: none;
  transition: color .35s $easing-alpha-out;

  &:visited {
    color: $color-text-beta;
  }

  &:hover,
  &:active,
  &:focus {
    color: darken($color-text-beta, 10);
  }
}

p {
  @include ms-margin(zero zero 0);
  &:last-child {
    @include ms-margin-bottom(zero);
  }

  &.paragraph--decreased-width {
    @include breakpoint(break-2) {
      padding-right: span(2) + gutter();
    }

    @include breakpoint(break-3) {
      padding-right: span(4) + 2 * gutter();
    }
  }
}

// Paragraph is adjacent sibling of any heading
.headline-paragraph__headline {
  & + p {
    @include ms-margin-top($spacing-heading-paragraph);
  }
}

.meta {
  @include ms-font-size(-1);
}


// --------------------------------------------------------------------------
// 04. Lists
// --------------------------------------------------------------------------

ul,
ol,
dt,
dd {
  padding: 0;
  margin: 0;
}

// Lists
ul,
ol {
  li {
    list-style-type: none;
  }
}

.-standard-list ul li {
  position: relative;
  padding-left: 10px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 4px;
    height: 4px;
    left: 0;
    top: .6em;
    border-radius: 100%;
    background-color: $color-beta;
  }
}

.-check-list ul li {
  position: relative;
  padding-left: 28px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 9px;
    height: 4px;
    left: 6px;
    top: calc(.85em + 4px);
    border-radius: 1px;
    background-color: $color-beta;
    transform-origin: 0 0;
    transform: rotate(-135deg);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 14px;
    height: 4px;
    left: 6px;
    top: .85em;
    border-radius: 1px;
    background-color: $color-beta;
    transform-origin: 0 100%;
    transform: rotate(-45deg);
  }
}

.-dash-list ul li {
  position: relative;
  padding-left: 16px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    height: 1px;
    left: 0;
    top: .7em;
    background-color: $color-beta;
  }
}

.-number-list ol {
  counter-reset: li;

  & li {
    position: relative;
    padding-left: 31px;
    counter-increment: li;

    &:before {
      content: counter(li, decimal-leading-zero)'.';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      color: $color-beta;
    }
  }
}

// Paragraph is adjacent sibling of any heading
.headline-paragraph__headline {
  & + ul,
  & + ol {
    @include ms-margin-top($spacing-heading-list);
  }
}


// --------------------------------------------------------------------------
// 05. Various
// --------------------------------------------------------------------------

// Bold
b,
strong,
.bold {
  @include font-weight-body(medium);
}

// Italic
em,
i,
.italic {
  font-style: map-get($type-body, italic);
}

// Caption and inline small text
small,
.caption {
  @include font-family-heading;
  @include font-weight-heading(regular);
  @include ms-font-size(-1);
  font-style: normal;
}

small {
  line-height: 1;
}

.caption {
  color: lighten($color-text-alpha, 10%);
}

// Nice spacing for captions
h1 + .caption,
.alpha + .caption,
h2 + .caption,
.beta + .caption,
h3 + .caption,
.gamma + .caption {
  margin-top: -0.45em;
}

// Code block
code,
pre {
  @include font-family-monospace;
  background-color: lighten($color-alpha, 40%);
}

pre {
  border: 1px solid $color-grey-dark;
  display: block;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 0.3rem;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}

code {
  @include ms-font-size(-1);
  border-radius: 3px;
  line-height: 1;
  border: 1px solid $color-grey-dark;
  white-space: nowrap;
  margin: 0 0.2em;
  padding: 0.2em 0.4em;
}
