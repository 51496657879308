// --------------------------------------------------------------------------
// Helpers / States
// --------------------------------------------------------------------------

.is-cursor-pointer {
  cursor: pointer;
}

.is-hidden {
  display: none;
}

.is-relative {
  position: relative;
}

.is-absolute {
  position: absolute;
}
