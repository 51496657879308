// --------------------------------------------------------------------------
// Partials / Page Footer
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.page__footer .footer__breadcrumb {
  @include ms-padding(0 zero);
  background-color: $white;
  box-shadow: inset 0 1px 0 0 rgba(0, 74, 121, 0.05);
}

.page__footer .footer__breadcrumb .col-one {
  display: none;
  margin-bottom: 0;

  @include breakpoint(break-1) {
    display: block;
  }
}

.page__footer .footer__breadcrumb .col-two {
  @include breakpoint(break-1) {
    display: none;
  }

  @include breakpoint(break-2) {
    display: block;
  }
}

.no-touchevents .page__footer .footer__breadcrumb .col-two {
  @include breakpoint(break-2) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.page__footer .module-backtotop  {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover > a {
    opacity: .5;
  }

  @include breakpoint(break-1) {
    justify-content: flex-end;
  }
}

.page__footer .module-backtotop > a {
  display: inline-block;
  position: relative;
  @include ms-font-size(-1);
  color: $color-beta;
  transition: opacity .25s $easing-alpha-out;

  &:visited,
  &:active,
  &:focus,
  &:hover {
    color: $color-beta;
  }
}

.page__footer .module-backtotop .backtotop-icon {
  display: none;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border: 1px solid rgba($color-alpha, .25);
  border-radius: 100%;
  @include ms-margin-left(0);

  &:after {
    @include icomoon("\e900");
    @include ms-font-size(-1);
    color: $color-alpha;
  }

  @include breakpoint(break-1) {
    display: flex;
  }
}

.page__footer .footer__nav-wrapper > .col-one,
.page__footer .footer__nav-wrapper > .col-two {
  @include ms-margin-bottom(0, break-0);
  @include ms-margin-bottom($spacing-vertical-gutter, break-1 to break-2);
  @include ms-margin-bottom(zero, break-3 to break-4);
}

.page__footer .footer__nav-wrapper > .col-three {
  @include ms-margin-bottom(0, break-0);
  @include ms-margin-bottom(zero, break-1 to break-4);
}

.page__footer .footer__bottombar {
  background-color: $color-alpha;
}

.page__footer .footer__bottombar-wrapper {
  flex-direction: column-reverse;

  @include breakpoint(break-1) {
    flex-direction: row;
  }
}

.page__footer .module-copyright {
  display: flex;
  justify-content: center;
  width: 100%;
  color: $white;

  @include breakpoint(break-1) {
    justify-content: flex-start;
    width: auto;
  }
}

.page__footer .module-nav.module-nav--legal {
  display: flex;
  justify-content: center;
  width: 100%;
  @include ms-margin-bottom(0, break-0);
  @include ms-margin-bottom(zero, break-1 to break-4);

  @include breakpoint(break-1) {
    justify-content: flex-end;
    width: auto;
  }
}
