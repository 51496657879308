////
/// Structural mixins
///
/// @group structure
/// @author dm
////


/// Generates the columns
///
/// @todo Add examples
/// @todo Performance update needed
///
/// @access private
/// @param {String} $base-name - Base name
/// @param {String} $alias - Alias
/// @param {List} $units - Column units
@mixin columns($base-name, $alias, $units) {
  @if $base-name != null and $units != null {
    $name: null;
    $string-value: null;
    @each $n in $units {
      $string-value: inspect($n);
      $string-value: str-replace($string-value, ".", "-");
      $name: #{$base-name}__bp#{$alias}-#{$string-value};
      .#{$name} {
        @include span($n);
      }
    }
  }@else {
    @warn "columns: Check your variables!";
  }
}

/// Generates the grid classes
///
/// @todo Add examples
/// @todo Performance update needed
///
/// @param {String} $base-name - Base name
/// @param {List} $units - Column units
/// @param {Map} $bps ($breakpoints) - Breakpoints to generate the grid classes for
@mixin grid($base-name, $units, $bps: $breakpoints) {
  @if $base-name != null and $units != null and $bps != null {
    // Iterate over the breakpoints
    $alias: 0;
    @each $bp, $value in $bps {
      @include breakpoint($break: $bp, $bps: $bps) {
        @include columns($base-name, $alias, $units);
      }

      $alias: $alias + 1;
    }
  }@else {
    @warn "grid: Check your variables!";
  }
}

/// Generates `max-width` css value in percentage (**preferred!**) defined
/// in `$maxwidths` for all breakpoints defined in the `$breakpoints`
/// config variable.
///
/// @example scss - Add max-width in percentage for all breakpoints
///   @include maxwidth;
/// @see $breakpoints
/// @see $maxwidths
@mixin maxwidth {
  max-width: percentage(nth($widths, 1));
  // Loop through breakpoints
  @if $breakpoints-limit > 1 {
    @for $i from 2 through $breakpoints-limit {
      $bp-value: nth($points, $i);
      @include breakpoint($bp-value) {
        $maxwidth-val: nth($widths, $i);
        @if (str-index(inspect($maxwidth-val), 'px')) {
          max-width: nth($widths, $i);
        } @else {
          max-width: percentage(nth($widths, $i));
        }
      }
    }
  }
}

/// Generates `max-width` css value in px (**bad!**) defined
/// in `$maxwidths` for all breakpoints defined in the `$breakpoints`
/// config variable.
///
/// @example scss - Add max-width in percentage for all breakpoints
///   @include maxwidth-px;
/// @see $breakpoints
/// @see $maxwidths
@mixin maxwidth-px {
  max-width: nth($widths, 1);
  // Loop through breakpoints
  @if $breakpoints-limit > 1 {
    @for $i from 2 through $breakpoints-limit {
      $bp-value: nth($points, $i);
      @include breakpoint($bp-value) {
        max-width: nth($widths, $i);
      }
    }
  }
}
