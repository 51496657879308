// --------------------------------------------------------------------------
// Fonts / Icomoon
// --------------------------------------------------------------------------

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/icomoon.eot?dsbb04');
  src: url('../fonts/icomoon/icomoon.eot?dsbb04#iefix') format('embedded-opentype'),
  url('../fonts/icomoon/icomoon.woff?dsbb04') format('woff'),
  url('../fonts/icomoon/icomoon.ttf?dsbb04') format('truetype'),
  url('../fonts/icomoon/icomoon.svg?dsbb04#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  &:after, &:before {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.icon-angle-up-before:before {
  content: "\e900";
}
.icon-angle-up-after:after {
  content: "\e900";
}

.icon-angle-right-before:before {
  content: "\e901";
}
.icon-angle-right-after:after {
  content: "\e901";
}

.icon-angle-down-before:before {
  content: "\e902";
}
.icon-angle-down-after:after {
  content: "\e902";
}

.icon-angle-left-before:before {
  content: "\e903";
}
.icon-angle-left-after:after {
  content: "\e903";
}

.icon-check-before:before {
  content: "\e904";
}
.icon-check-after:after {
  content: "\e904";
}

.icon-search-before:before {
  content: "\e905";
}
.icon-search-after:after {
  content: "\e905";
}

.icon-caret-right-before:before {
  content: "\e906";
}
.icon-caret-right-after:after {
  content: "\e906";
}