// --------------------------------------------------------------------------
// Modules / Prev Next Nav
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-prev-next-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.module-prev-next-nav .nav__item {
  width: 100%;
  background-color: $color-alpha;
  text-align: center;
  cursor: pointer;

  &:first-child {
    @include ms-margin-bottom($content-element-gutter-mobile, break-0);
    @include ms-margin-bottom($content-element-gutter, break-1);
  }

  @include breakpoint(break-2) {
    width: span(6);

    &:first-child {
      @include ms-margin-bottom(zero);
    }
  }
}

.module-prev-next-nav .nav__item a {
  display: block;
  @include ms-padding($content-element-gutter-mobile, break-0);
  @include ms-padding($content-element-gutter, break-1 to break-4);
}

.module-prev-next-nav .nav__item .item__title {
  margin-bottom: 6px;

  .beta {
    color: $white;
  }

}
