// --------------------------------------------------------------------------
// Helpers / Check Media
// --------------------------------------------------------------------------

// Adds active media query breakpoint after body.
// Useful for debugging or JavaScript.
@if $breakpoints-limit >= 1 {
  @for $i from 1 through $breakpoints-limit {
    $bp-value: nth($points, $i);
    @include breakpoint($bp-value) {
      body:after {
        content: "break-#{$i - 1}";
        display: none;
      }
    }
  }
}
