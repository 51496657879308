// --------------------------------------------------------------------------
// Modules / Headline-Paragraph
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-headline-paragraph.headline--decreased-width .headline-paragraph__headline {
  @include breakpoint(break-1) {
    padding-right: span(2) + gutter();
  }

  @include breakpoint(break-3) {
    padding-right: span(4);
  }
}

.module-headline-paragraph.paragraph--decreased-width p,
.module-headline-paragraph.paragraph--decreased-width ul {
  @include breakpoint(break-1) {
    padding-right: span(2) + gutter();
  }

  @include breakpoint(break-3) {
    padding-right: span(4);
  }
}

.module-headline-paragraph .headline-paragraph__headline.--inverted {
  display: flex;
  flex-direction: column-reverse;
}

.module-headline-paragraph a {
  box-shadow: inset 0 -2px 0 0 rgba($color-alpha, .25);

  &:hover,
  &:active,
  &:focus {
    box-shadow: inset 0 -2px 0 0 rgba($color-alpha, .75);
  }
}
