// --------------------------------------------------------------------------
// Modules / Simple Table
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-simple-table {

}

.module-simple-table .table__title {
  @include ms-margin-bottom(4);
}

.module-simple-table .row__column {
  @include ms-padding(-1 1);
}

.module-simple-table .table__row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @include ms-margin-bottom(-2);
}

.module-simple-table .row__column {
  width: 100%;
}

.module-simple-table .row__column {
  border: 1px solid rgba($color-alpha, .25);
  border-top:none;
  border-right:none;
  flex-grow: 1;

  @include breakpoint(break-1) {
    width: auto;
  }
}

.module-simple-table .row__column:first-child {

  @include breakpoint(break-1) {
    border-right: 0 none;
    border-left: 0 none;
    border-bottom: 1px solid rgba($color-alpha, .25);
  }
}

.module-simple-table .row__column span {
  display: block;
}
