////
/// Breakpoints - Helpers to render different media queries
/// @group bp
/// @author pd and dm
////



/// Render a print media query
///
/// @example scss - Display hidden only on print
///  @include print {
///    display: hidden;
///  }
@mixin print {
  @include render-media-query("print") {
    @content;
  }
}


/// Render a media query breakpoint
///
/// Either set `$break` to a key that is included in the `$bps` map and render
/// a `min` media query. (-> _mobile first_)
///
/// Or set `$break` and/or `break-max` to custom pixel values
/// (**without the px extension**) and render a custom `min` `max` media query.
///
/// Partly based on: https://gist.github.com/timknight/03e6335b8816aa534cf7
///
/// @param {String|Number} $break (0) - Breakpoint key for `$bps` map or
/// manual breakpoint value for `min` query
/// @param {Number} $break-max (0) - Breakpoint key for `$bps` map or
/// manual breakpoint value for `max` query
/// @param {Map} $bps [$breakpoints] - Breakpoints map
///
/// @requires $breakpoints
/// @requires $breakpoints-default-media-type
/// @requires bourbon.em
///
/// @link http://bourbon.io/docs/#px-to-em
/// @link https://gist.github.com/timknight/03e6335b8816aa534cf7
/// @link https://developer.mozilla.org/en-US/docs/Web/CSS/@media
///
/// @example scss - Render `break-1` from the global `$breakpoints` map
///  @include breakpoint(break-1);
///
/// @example scss - Render `break-1` from custom `$bps` map
///  @include breakpoint($break: break-1, $bps: (break-1: 790));
///
/// @example scss - Render custom `min` media query
///  @include breakpoint($break: 800);
///
/// @example scss - Render custom `min` media query _shortcut_
///  @include breakpoint(800);
///
/// @example scss - Render custom `max` media query
///  @include breakpoint($break-max: 900);
///
/// @example scss - Render custom `min` `max` media query
///  @include breakpoint($break: 800, $break-max: 1400);
///
/// @example scss - Render custom `min` `max` media query _shortcut_
///  @include breakpoint(800, 1400);
@mixin breakpoint($break: 0, $break-max: 0, $bps: $breakpoints, $pbp: $print-breakpoints) {
  // Type of break variable
  $break-type: type-of($break);
  $break-max-type: type-of($break-max);

  // If given breakpoint is breakpoint key (-> string)
  @if $break-type == string {
    // Check if it is a correct breakpoint key (e.g. break-1 etc.)
    @if map-has-key($bps, $break) {
      // Get the breakpoint value
      $bp-value: map-get($bps, $break);
      // If the bp value is 0 directly output the content
      @if $bp-value == 0 {
        @content;
      // If we got a bp value != 0 wrap the content in a media query
      }@else {
        // Check if it is a correct breakpoint key (e.g. break-1 etc.)
        $bp-key: 0;
        @if map-has-key($pbp, $bp-value) {
          // Get the breakpoint key
          $bp-key: map-get($pbp, $bp-value);
        }

        // Calculate the em value and output the media query
        $query: "(min-width: #{em($bp-value)})";
        @include render-media-query($query: $query, $pbp : $bp-key) {
          @content;
        }
      }
    }@else {
      @warn "#{$break} is not a set breakpoint variable";
    }
  // If the given max value is a string skip it
  }@else if $break-max-type == string {
    @warn "#{$break-max-type} is not valid to use as a breakpoint (no string!)";
  // If given breakpoint is a custom breakpoint value in px (-> number)
  }@else if $break-type == number or $break-max-type == number {
    // No max and min breakpoint render instantly
    @if $break == 0 and $break-max == 0 {
      @content;
    // Check which media query we have (min, max, min/max)
    }@else {
      $query: all;

      // Min & Max
      @if $break != 0 and $break-max != 0 {
        $query: "(min-width: #{em($break)}) and (max-width: #{em($break-max)})";
      }

      // Min
      @else if $break != 0 and $break-max == 0 {
        $query: "(min-width: #{em($break)})";
      }

      // Max
      @else if $break == 0 and $break-max != 0 {
        $query: "(max-width: #{em($break-max)})";
      }

      // Check if it is a correct breakpoint key (e.g. break-1 etc.)
      $bp-key: 0;
      @if map-has-key($pbp, $break) {
        // Get the breakpoint key
        $bp-key: map-get($pbp, $break);
      }

      // Render the media query
      @include render-media-query($query: $query, $pbp : $bp-key) {
        @content;
      }
    }
  // No valid breakpoint value
  }@else {
    @warn "#{$break} is not valid to use as a breakpoint";
  }
}


/// Renders the css media query output
///
/// @access private
/// @param {String|Bool} $media-type ($breakpoints-default-media-type) - Containing the `media type`
/// @param {String} $query - Containing the `media features` query
///
/// @link http://www.w3.org/TR/css3-mediaqueries/#media1
@mixin render-media-query($media-type: $breakpoints-default-media-type, $query: false, $pbp: 0) {
  @if not $media-type and $query {
    @media #{$query} {
      @content;
    }
  }@else if $media-type == "screen" and $query {
    @media #{$media-type} and #{$query} {
      @content;
    }
  }@else if $media-type == "print" and $query and $pbp == 1 {
    @media #{$media-type}, screen and #{$query} {
      @content;
    }
  }@else if $media-type and $query {
    @media #{$query} {
      @content;
    }
  }@else {
    @warn "Media type and query defined correctly?";
  }
}
