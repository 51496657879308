// --------------------------------------------------------------------------
// Modules / Hero (Standalone)
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-hero {
  position: relative;
}

.module-hero .hero__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.module-hero .hero__image .image__image {
  display: block;
  width: 100%;
  height: 100%;
}

.module-hero .hero__content-wrapper {
  @include ms-padding(9 zero $spacing-section-mobile, break-0);
  @include ms-padding(12 zero $spacing-section, break-1 to break-4);
}

.module-hero.-big .hero__content-wrapper {
  @include ms-padding(11 zero $spacing-section-mobile, break-0);
  @include ms-padding(14 zero $spacing-section, break-1 to break-4);
}

.module-hero.-with-breadcrumb .hero__content-wrapper {
  padding-bottom: 0;
}

.module-hero .hero__content-wrapper .content-wrapper__content {
  width: 100%;
  overflow: hidden;
}

.module-hero.-with-breadcrumb .hero__content-wrapper .content-wrapper__content {
  @include ms-margin-bottom($spacing-section-mobile, break-0);
  @include ms-margin-bottom($spacing-section, break-1 to break-4);
}

.module-hero .content__title {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  & h1.-color-white,
  & h2.-color-white {
    text-shadow: 2px 2px rgba($color-grey-dark, .25);
  }

  @include breakpoint(break-2) {
    width: span(9);
  }

  @include breakpoint(break-3) {
    width: span(6);
  }
}

.module-hero .content__link {
  width: 100%;
  @include ms-margin-top($content-element-gutter-mobile, break-0);
  @include ms-margin-top($content-element-gutter, break-1 to break-4);

  @include breakpoint(break-2) {
    width: span(9);
  }

  @include breakpoint(break-3) {
    width: span(6);
  }
}

.module-hero.-with-breadcrumb .hero__content-wrapper .content-wrapper__breadcrumb {
  position: relative;
  width: 100%;
  @include ms-padding(0);
  background-color: rgba($color-alpha, .85);
}

.module-hero.-with-breadcrumb .module-nav--breadcrumb .nav__crumb:after {
  color: $white;
}

.module-hero.-with-breadcrumb .module-nav--breadcrumb .nav__crumb a {
  color: $white;

  &:visited,
  &:active,
  &:focus,
  &:hover {
    color: $white;
  }
}

.module-hero.-with-breadcrumb .module-nav--breadcrumb .nav__crumb span {
  color: rgba($white, .5);
}

.module-hero.-with-breadcrumb .module-nav--breadcrumb .nav__crumb--first a,
.module-hero.-with-breadcrumb .module-nav--breadcrumb .nav__crumb--first span:not(.crumb__title) {
  color: $white;
}

.module-hero {
  & h1,
  & h2,
  & .content__link {
    transform: translateX(0);
    opacity: 1;
  }
}
