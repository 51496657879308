// --------------------------------------------------------------------------
// Modules / Image Preloaded
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-image-preloaded {
  position: relative;
  overflow: hidden;

  &.-no-alpha {
    background-color: $white;
  }
}

//.module-image-preloaded img {
//  width: 100%;
//}

.module-image-preloaded .image-preloaded__placeholder {
  opacity: .5;
  filter: blur(15px);
  transition: opacity 1.5s $easing-alpha-out;
}

.module-image-preloaded.-loaded .image-preloaded__placeholder {
  opacity: 0;
}

.module-image-preloaded .image-preloaded__loaded {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  & img {
    opacity: 0;
    transition: opacity 1.5s $easing-alpha-out;

    &.-loaded {
      opacity: 1;
    }
  }
}
