////
/// Config - global variables, colour palettes, etc.
///
/// @group config
/// @author wf
////


// TABLE OF CONTENTS
// -----------------
// 01. Fonts
// 02. Type Setup
// 03. Breakpoints
// 04. Modular Scale
// 05. Spacings and Borders
// 06. Colors – a set of predefined colors ready to use or customize
// 07. Easings – a set of cool easings
// 08. Various
// 09. Flags for lang-nav


// --------------------------------------------------------------------------
// 01. Fonts - families and variables
// --------------------------------------------------------------------------

// Basic CSS font stacks
$georgia: Georgia, Cambria, "Times New Roman", Times, serif;
$helvetica: "Helvetica Neue", Helvetica, Arial, sans-serif;
$lucida-grande: "Lucida Grande", Tahoma, Verdana, Arial, sans-serif;
$monospace: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
$verdana: Verdana, Geneva, sans-serif;

// Typefaces served by externals (Google, Typekit, MyFonts, ...)
// NOTE: Depends on the included stylesheet
$opensans: "Open Sans";
$opensanscondensed: "Open Sans";


// --------------------------------------------------------------------------
// 02. Type Setup
// --------------------------------------------------------------------------

/// Baseline height Body
///
/// @type Number
$line-height-body: 1.6;

/// Body type settings
///
/// @type Map
$type-body: (
  font-family: (opensans, $helvetica),
  light: 300,
  regular: 400,
  medium: 600,
  bold: 700
);

/// Headline type settings
///
/// @type Map
$type-heading: (
  font-family: ($opensanscondensed, $helvetica),
  light: 700,
  regular: 700,
  medium: 700,
  bold: 700
);

/// Monospace type settings
///
/// @type Map
$type-monospace: (
  font-family: $monospace,
  regular: 400
);

// --------------------------------------------------------------------------
// 03. Breakpoints – breakpoint related variables
// --------------------------------------------------------------------------

/// Default `media-type` used by the `breakpoint` mixin
///
/// Use `screen` or `false` to disable the media-type
/// Set to `print` if a _print.scss file is present
///
/// @type String | Bool
/// @see {mixin} breakpoint
/// @link http://www.w3.org/TR/CSS2/media.html#media-types
$breakpoints-default-media-type: false;

/// Major breakpoints
///
/// @type Map
$breakpoints: (
  break-0: 0,
  break-1: 640,
  break-2: 1024,
  break-3: 1440,
  break-4: 1680
);

/// Print breakpoints (items have to match $breakpoints; used if
/// $breakpoints-default-media-type is set to "print"); 1 prints
/// additional `print, ` in media-query, 0 doesn't
///
/// @type Map
$print-breakpoints: (
  0: 1,
  640: 1,
  1024: 1,
  1440: 0,
  1680: 0
);

/// Root font-sizes for each breakpoint.
///
/// **NOTE:** Make sure to have as many sizes as `$breakpoints` above.
///
/// @type Map
/// @see $breakpoints
$rootsizes: (
  rootsize-0: 18,
  rootsize-1: 20,
  rootsize-2: 20,
  rootsize-3: 20,
  rootsize-4: 20
);

/// Control the line-length with max-width values for the container.
/// Aim for 75–100 characters a line when possible, at smaller sizes
/// type size is more important.
///
/// **NOTE:** Make sure to have as many widths as breakpoints exist in the
/// `$breakpoints` map.
/// **NOTE:** It's possible to set the max-width in pixels. To do so, write
/// the pixel value including the unit, e.g. `maxwidth-4: 1360px`
///
/// @type Map
/// @see $breakpoints
$maxwidths: (
  maxwidth-0: 0.86,
  maxwidth-1: 0.90,
  maxwidth-2: 0.90,
  maxwidth-3: 0.90,
  maxwidth-4: 0.90
);


// --------------------------------------------------------------------------
// 04. Modular Scale – for more meaningful typography
// --------------------------------------------------------------------------

/// Modular scale base.
///
/// Overwrites modular-scale default variables.
///
/// Please see http://github.com/at-import/modular-scale
///
/// @type String
/// @link http://github.com/at-import/modular-scale
$ms-base: 1em;

/// Modular scale ratio.
///
/// Value must be one of the modular scale variables.
///
/// Please see http://github.com/at-import/modular-scale
///
/// @type String
/// @link http://github.com/at-import/modular-scale
$ms-ratio: $minor-third;

/// Setting responsive modular-scales.
/// Please see http://github.com/at-import/modular-scale
/// **NOTE:** Use appropriate scales for viewport sizes.
/// **NOTE:** Make sure to have as many scales as `$breakpoints` above.
///
/// @type Map
/// @see $breakpoints
/// @link http://github.com/at-import/modular-scale
$modular-scale: (
  scale-0: $minor-third,
  scale-1: $minor-third,
  scale-2: $minor-third,
  scale-3: $minor-third,
  scale-4: $minor-third
);

/// EM base value used in bourbon em calculations
///
/// **Don't drop the variable since it is needed by
/// bourbon calculations!**
///
/// @link http://bourbon.io/docs/#em-base
$em-base: 16px;

// --------------------------------------------------------------------------
// 05. Spacings and Borders
// --------------------------------------------------------------------------

/// Global modular scale value: _alpha_
///
/// @type Number
$spacing-alpha: -2;

/// Globale modular scale value: _beta_
///
/// @type Number
$spacing-beta: 0;

/// Global modular scale value: _gamma_
///
/// @type Number
$spacing-gamma: 3;

/// Global modular scale value: _delta_
///
/// @type Number
$spacing-delta: 5;

/// Global modular scale value: _epsilon_
///
/// @type Number
$spacing-epsilon: 6;

/// Global modular scale value: _zeta_
///
/// @type Number
$spacing-zeta: 9;

/// Global modular scale value for image headings
///
/// **NOTE:** 0 = initial value on scale (1em)
///
/// @type Number
$spacing-img-heading: -1;

/// Global modular scale value for paragraph headings
///
/// **NOTE:** 0 = initial value on scale (1em)
///
/// @type Number
$spacing-heading-paragraph: $spacing-delta;

/// Global modular scale value list of headings
///
/// **NOTE:** 0 = initial value on scale (1em)
///
/// @type Number
$spacing-heading-list: 2;

/// Global spacing value for the "vertical gutter"
/// used to keep the bottom margin for _column-wrapper_,
/// _content-item_ and _col_ in sync.
///
/// @type Number
$spacing-vertical-gutter: $spacing-delta;
$spacing-vertical-gutter-mobile: $spacing-gamma;
$content-element-gutter: $spacing-vertical-gutter;
$content-element-gutter-mobile: $spacing-vertical-gutter-mobile;

/// Global modular scale value for section spacings
///
/// @type Number
$spacing-section: $spacing-zeta;
$spacing-section-mobile: $spacing-epsilon;

/// Global border radius
///
/// @type String
$border-radius: 3px;

// --------------------------------------------------------------------------
// 06. Colors – a set of predefined colors ready to use or customize
// --------------------------------------------------------------------------

/// Color white
///
/// @type Color
$white: rgb(255, 255, 255);

/// Color black
///
/// @type Color
$black: rgb(0, 0, 0);

/// Main color alpha
///
/// @type Color
$color-alpha: rgb(83, 138, 196);

/// Main color beta
///
/// @type Color
$color-beta: rgb(110, 115, 110);

/// Color: Light grey
///
/// @type Color
$color-grey-light: rgb(231, 231, 231);

/// Color: Medium grey
///
/// @type Color
$color-grey-medium: rgb(110, 115, 110);

/// Color: Dark grey
///
/// @type Color
$color-grey-dark: rgb(74, 74, 74);

/// Color text alpha
///
/// @type Color
$color-text-alpha: $color-grey-dark;

/// Color text beta
///
/// @type Color
/// @requires $color-alpha
$color-text-beta: $color-alpha;
/// Color: Border

/// Color: grey
///
/// @type Color
$color-grey: rgb(110, 115, 110);
$color-grey-70: rgba($color-grey, .70);
$color-grey-25: rgba($color-grey, .25);

/// Color: Red
///
/// @type Color
$color-red: rgb(236, 16, 16);
$color-red-05: rgba($color-red, .05);
$color-red-60: rgba($color-red, .60);

/// Color: Green
///
/// @type Color
$color-green: rgb(0, 255, 0);
///
/// @type Color
$color-border: $color-grey-70;

/// Color: Box shadow
///
/// @type Color
$color-box-shadow: $color-grey-25;

/// Color: Error
///
/// @type Color
$color-error: $color-red;

/// Color: Error / Background
///
/// @type Color
$color-error-background: $color-red-05;

/// Color: Error / Border
///
/// @type Color
$color-error-border: $color-red-60;
// --------------------------------------------------------------------------
// 07. Easings – a set of cool easings
// --------------------------------------------------------------------------

/// Easing alpha
///
/// @type Easing
$easing-alpha-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);   // easeInCubic
$easing-alpha-out: cubic-bezier(0.215, 0.61, 0.355, 1);     // easeOutCubic
$easing-alpha-in-out: cubic-bezier(0.645, 0.045, 0.355, 1); // easeInOutCubic

/// Easing alpha
///
/// @type Easing
$easing-alpha: cubic-bezier(0.215, 0.61, 0.355, 1);

// --------------------------------------------------------------------------
// 08. Various
// --------------------------------------------------------------------------

// Shadows
$shadow-alpha: 0px 5px 20px 0 rgba(189, 189, 189, .5);
$text-shadow-alpha: 2px 2px 0 rgba($black, .2);
$text-shadow-beta: 1px 1px 0 rgba($black, .1);

// --------------------------------------------------------------------------
// 09. Flags for lang-nav
// --------------------------------------------------------------------------

// Flag images (4x3)
$flag-de: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAAC0AgMAAADX8kl7AAAACVBMVEX/zgAAAADdAAAgURbtAAAAOElEQVRo3u3LMREAAAgEIEta0pRm8BwfdqofSpZlWZZlWZZlWY7M8yDLsizLsizLsixnZgAAADhaeAgCw8aC/igAAAAASUVORK5CYII=';
$flag-uk: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAAC0CAMAAACdQlHaAAAAPFBMVEWPj7yvr8/cUFDSICDsn5/fYGD539/PEBD87+8QEHBAQIy/v9nv7/XZQEDyv7+AgLPmgIAAAGb////MAADjSaqTAAADNklEQVR42u3cy04bYRAF4ROSEMBX/nn/d43FphfIMqRUgsin9j3qb9bTk1ettabtA53XdNK2KrjgggsuuOCCvxD8fG/g/en5jsAvu6wFyB5Y4q51AQOyB5a4b2BA9sAKd8CY7IM5d8Cc7IM5d8CQ7IM5d3oaMCb7YM49/xkwJvtgzt22AWOyD+bcAXOyD+bcAXOyD+bcAXOyD+bcAXOyD+bcAXOyD+bcAQOyB1a4A+ZkH8y5A+ZkH8y5A+ZkH8y5A6ZkH8y5Uw5HSPbBnDsdsm2A7IEl7nFdwIDsgSXuegMDsgZ2uAPmZB/MuQPmZB/MuQPmZB/MuQPmZB/MuQMGZA+scAfMyT6YcwfMyT6YcwfMyT6YcweMyTqYc6fMDCDLYM6dMnOIrII5d8rMQrIL5twBC2QOFrgDFsgYLHAHLJAxWOAOWCFzMOdOU3ZLIHOwxF37vL5IZA5WVov0XAr+zFo/f31irWivkoEl7hvYIhPwD4U7YIVMwAZ3wIAsgFXugDmZg13ugAFZALvcAXMyB4vcKbdmno7XezLBj8frrX8vi+SAb1dwwQUXXHDBBRdc8HcEb3dWwQUXXHDBBRdccMEFF1xwwQUXXHDBBRdccMEFF1xwwQUXXHDBBRdc8H9YzpPY9oEO58mrny0VXHDBBRdccMEFFzyhcnrX7oOjj78PVzur4OPD4VoPx7Wm/Xterh2X3uZuN+5OPfDto9fr56VxuAAsk2NwAVgnR+ACsE+Oxt0T8F4jx+Kengn49LKTyLG4+Nc0EjkWF4MlciQuB0vkSFwOlsiRuBwskSNxOVgiR+JysESOxOVgiRyJy8ESORKXgyVyJC4HS+RIXA6WyOFcCSyRQ7kaWCKHcUWwRA7hqmCJHMB1wRI5gOuCJXIA1wVL5ACuC1bIFzDgumCJHMB1wRI5gOuCJXIA1wVL5ACuC5bIAVwXLJEDuC5YIgdwXbBEDuC6YIkcwHXBEjmA64IlcgDXBUvkAK4LlsgBXBcskQO4LlgiB3BdsEQO4LpgiRzAdcESOYDrgh3yCuC6YIkcwHXBEjmA64IlcgDXBUvkAK4LlsgBwyrYquCCCy644IIL/jrwXxiWzm4ASyBoAAAAAElFTkSuQmCC';
