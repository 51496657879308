////
/// Modular Scale - functions for the typographic joy
/// @group ms
/// @author pd and dm
////


/// Checks if a list contains any falsy
///
/// @access private
/// @param {List} $list - List
///
@function ms-contains-falsy($list) {
  @each $item in $list {
    @if not $item {
      @return true;
    }
  }
  @return false;
}

/// Hands back the modular scale ratio for a given position in the
/// `$modular-scale` map defined in the config.
///
/// @access private
/// @param {Number} $pos - Position
///
/// @returns {Number} Modular scale ratio
///
/// @see $modular-scale
///
@function ms-get-scale-ratio($pos) {
  @if not $modular-scale {
    @warn 'Please define a modular scale map in your config.';
  } @else {
    $ratio: map-get($modular-scale, "scale-#{$pos}");
    @return $ratio;
  }
}

/// Hands back the modular scale value for the given
/// `$val`, `$base` and modular scale `$ratio`
///
/// @access private
/// @param {Number} $val - How much up the modular scale
/// @param {String} $base - Modular scale base in px
/// @param {String} $ratio - Ratio
///
/// @returns {*} Modular scale ratio or `null` or `0`
///
/// @requires modular-scale.ms - Uses http://github.com/at-import/modular-scale
///
@function ms-get-value($val, $base, $ratio) {
  @if $val == null {
    @return null;
  } @else if $val == zero {
    @return 0;
  } @else {
    @return ms($val, $base, $ratio);
  }
}

/// Collapses the given list of directional `$vals` for the given `$ratio` and
/// replaces them with modular scale values.
///
/// @access private
/// @param {Number} $ratio - Modular scale ratio
/// (https://github.com/at-import/modular-scale)
/// @param {List} $vals - List of values
///
/// @returns {List} Collapsed list
///
/// @link http://github.com/at-import/modular-scale
/// @requires ms-get-value
///
@function ms-collapse-directionals($ratio, $vals) {
  $output: null;
  $length-vals: length($vals);
  $base: $ms-base;

  $a: nth($vals, 1);
  $b: if($length-vals < 2, $a, nth($vals, 2));
  $c: if($length-vals < 3, $a, nth($vals, 3));
  $d: if($length-vals < 2, $a, nth($vals, if($length-vals < 4, 2, 4)));

  @if $a == $b and $a == $c and $a == $d {
    $output: ms-get-value($a, $base, $ratio);
  } @else if $a == $c and $b == $d {
    $output: ms-get-value($a, $base, $ratio)
             ms-get-value($b, $base, $ratio);
  } @else if $b == $d {
    $output: ms-get-value($a, $base, $ratio)
             ms-get-value($b, $base, $ratio)
             ms-get-value($c, $base, $ratio);
  } @else {
    $output: ms-get-value($a, $base, $ratio)
             ms-get-value($b, $base, $ratio)
             ms-get-value($c, $base, $ratio)
             ms-get-value($d, $base, $ratio);
  }

  @return $output;
}

/// Appends the given breakpoint `$index` to the given `$bp-keys-list`.
///
/// Before adding the breakpoint the function checks if the breakpoint key is
/// available in the global `$breakpoints` map.
///
/// @access private
///
/// @param {List} $bp-keys-list - List of breakpoint keys
/// @param {Number} $index - Breakpoint index to add
///
@function append-index-to-bp-keys-list($bp-keys-list, $index) {
  $key: 'break-#{$index}';
  @if map-has-key($breakpoints, $key) {
    @return append($bp-keys-list, $key);
  } @else {
    @warn 'Key found that is not included in the breakpoints ' +
    'map. Append skipped.';
    @return null;
  }
}

/// Builds up a breakpoints keys list for the given `$start-index` and
/// `$end-index`
///
/// @access private
///
/// @param {Number} $start-index - Start Index
/// @param {Number} $end-index - End Index
///
/// @return {List} The build breakpoint keys list
///
@function build-bp-keys-list($start-index, $end-index) {
  $bp-keys-list: ();
  @for $i from $start-index through $end-index {
    $bp-keys-list: append-index-to-bp-keys-list($bp-keys-list, $i);
  }
  @return $bp-keys-list;
}

/// Builds up a breakpoint key list from the given
/// `$mode` and `$bp-keys` with breakpoint keys from
/// the `$breakpoints` map.
///
/// @access private
///
/// @param {Number} $mode - Mode received from `ms-parse-bp-keys-mode` function
/// @param {List | String} $bp-keys - Special `$bp-keys` list which can contain
/// magic keywords like `all`, `continue` or `to`
///
/// @return {List} List of `$bp-keys` which can be found `$breakpoints` map
///
/// @see $breakpoints
/// @see ms-parse-bp-keys-mode
///
@function ms-build-bp-key-list-from-mode($mode, $bp-keys) {
  @if $mode == 0 {
    @return map-keys($breakpoints);
  } @else if $mode == 1 or $mode == 2 {
    @return $bp-keys;
  } @else if $mode == 3 {
    $start-bp-key: nth($bp-keys, 1);
    @if map-has-key($breakpoints, $start-bp-key) {
      $start-index: to-number(str-slice($start-bp-key, 7));
      $end-index: $breakpoints-limit - 1;
      @return build-bp-keys-list($start-index, $end-index);
    } @else {
      @return 'The given start #{$start-bp-key} can not be found in the ' +
      'breakpoints #{$breakpoints} map!';
    }
  } @else if $mode == 4 {
    $start-bp-key: nth($bp-keys, 1);
    $end-bp-key: nth($bp-keys, 3);
    @if map-has-key($breakpoints, $start-bp-key) and
    map-has-key($breakpoints, $end-bp-key) {
      $start-index: to-number(str-slice($start-bp-key, 7));
      $end-index: to-number(str-slice($end-bp-key, 7));
      @return build-bp-keys-list($start-index, $end-index);
    } @else {
      @warn 'The given start #{$start-bp-key} or endpoint #{$end-bp-key} ' +
      'can not be be found in the breakpoints #{$breakpoints} map!';
      @return null;
    }
  } @else {
    @warn 'Invalid mode given.';
    @return null;
  }
}

/// Parses the `$bp-keys` submitted to the `ms-breakpoints` mixin
///
/// * Case Zero: `all` -> Use all breakpoints
/// * Case One: `break-0` -> One specific breakpoint
/// * Case Two: `break-0 break-1 break-2` -> A list of breakpoints
/// * Case Three: `break-2 continue` -> From break-2 till the end
/// * Case Four: `break-2 to break-4` -> From break-2 to break-4
///
/// @access private
///
/// @param {List | String} $bp-keys - List of breakpoint keys
///
/// @return {Number} Mode
///
@function ms-parse-bp-keys-mode($bp-keys) {
  $count: length($bp-keys);

  @if $count == 1 {
    $first-item: nth($bp-keys, 1);

    @if $first-item == all {
      @return 0;
    } @else {
      @return 1;
    }
  } @else if $count > 1 {
    $second-item: nth($bp-keys, 2);

    @if $second-item == continue {
      @return 3;
    } @else if $second-item == to {
      @return 4;
    } @else {
      @return 2;
    }
  } @else {
    @warn 'Could not parse the given #{$bp-keys}.';
  }
}

/// Parses the given `$bp-keys` list and hands back the complete breakpoint
/// keys list excluding the magic keywords like `to` and `continue`
///
/// @access private
///
/// @param {List} $bp-keys - Breakpoint keys + magic keywords (`to`, `continue`)
///
/// @return {List} List containing all the breakpoint keys
///
@function parse-bp-keys-list($bp-keys) {
  $bp-mode: ms-parse-bp-keys-mode($bp-keys);
  @return ms-build-bp-key-list-from-mode($bp-mode, $bp-keys);
}
