// --------------------------------------------------------------------------
// Base / Column (Grid - with the help of Susy (http://susy.oddbird.net/))
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Variables (defining the grid)
// 02. Base column class
// 03. Breakpoint 0 (break-0) columns
// 04. Breakpoint 1 (break-1) columns
// 05. Breakpoint 2 (break-2) columns
// 06. Breakpoint 3 (break-3) columns
// 07. Breakpoint 4 (break-4) columns
// 08. Pushes - uncomment all needed pushing classes
// 09. Special Columns - place various classes here


// --------------------------------------------------------------------------
// 01. Variables (defining the grid)
// --------------------------------------------------------------------------

$columns: 12;

$susy: (
  'columns': susy-repeat($columns),
  'gutters': 1 / 1.564,
  'spread': 'narrow',
  'container-spread': 'narrow',
);


// --------------------------------------------------------------------------
// 02. Base column class
// --------------------------------------------------------------------------

.col {
  width: 100%;
  @include ms-margin-bottom($spacing-vertical-gutter-mobile, break-0);
  @include ms-margin-bottom(zero, break-1);
}

.col:last-child {
  margin-bottom: 0;
}

// --------------------------------------------------------------------------
// 03. Breakpoint 0 (break-0) columns
// --------------------------------------------------------------------------

.col__b0-full {
  @include span($columns);
}

.col__b0-3 {
  @include span(3 of $columns);
}

.col__b0-3--last {
  @include span(3 of $columns last);
}

.col__b0-4 {
  @include span(4 of $columns);
}

.col__b0-4--last {
  @include span(4 of $columns last);
}

.col__b0-5 {
  @include span(5 of $columns);
}

.col__b0-5--last {
  @include span(5 of $columns last);
}

.col__b0-6 {
  @include span(6 of $columns);
}

.col__b0-6--last {
  @include span(6 of $columns last);
}

.col__b0-7 {
  @include span(7 of $columns);
}

.col__b0-7--last {
  @include span(7 of $columns last);
}

.col__b0-8 {
  @include span(8 of $columns);
}

.col__b0-8--last {
  @include span(8 of $columns last);
}

.col__b0-9 {
  @include span(9 of $columns);
}

.col__b0-9--last {
  @include span(9 of $columns last);
}


// --------------------------------------------------------------------------
// 04. Breakpoint 1 (break-1) columns
// --------------------------------------------------------------------------

@include breakpoint(break-1) {
  .col__b1-3 {
    @include span(3 of $columns);
  }
  .col__b1-3--last {
    @include span(3 of $columns last);
  }
  .col__b1-4 {
    @include span(4 of $columns);
  }
  .col__b1-4--last {
    @include span(4 of $columns last);
  }
  .col__b1-5 {
    @include span(5 of $columns);
  }
  .col__b1-5--last {
    @include span(5 of $columns last);
  }
  .col__b1-6 {
    @include span(6 of $columns);
  }
  .col__b1-6--last {
    @include span(6 of $columns last);
  }
  .col__b1-7 {
    @include span(7 of $columns);
  }
  .col__b1-7--last {
    @include span(7 of $columns last);
  }
  .col__b1-8 {
    @include span(8 of $columns);
  }
  .col__b1-8--last {
    @include span(8 of $columns last);
  }
  .col__b1-9 {
    @include span(9 of $columns);
  }
  .col__b1-9--last {
    @include span(9 of $columns last);
  }
}

// --------------------------------------------------------------------------
// 05. Breakpoint 2 (break-2) columns
// --------------------------------------------------------------------------

@include breakpoint(break-2) {
  .col__b2-3 {
    @include span(3 of $columns);
  }
  .col__b2-3--last {
    @include span(3 of $columns last);
  }
  .col__b2-4 {
    @include span(4 of $columns);
  }
  .col__b2-4--last {
    @include span(4 of $columns last);
  }
  .col__b2-5 {
    @include span(5 of $columns);
  }
  .col__b2-5--last {
    @include span(5 of $columns last);
  }
  .col__b2-6 {
    @include span(6 of $columns);
  }
  .col__b2-6--last {
    @include span(6 of $columns last);
  }
  .col__b2-7 {
    @include span(7 of $columns);
  }
  .col__b2-7--last {
    @include span(7 of $columns last);
  }
  .col__b2-8 {
    @include span(8 of $columns);
  }
  .col__b2-8--last {
    @include span(8 of $columns last);
  }
  .col__b2-9 {
    @include span(9 of $columns);
  }
  .col__b2-9--last {
    @include span(9 of $columns last);
  }
}

// --------------------------------------------------------------------------
// 06. Breakpoint 3 (break-3) columns
// --------------------------------------------------------------------------

@include breakpoint(break-3) {
  .col__b3-3 {
    @include span(3 of $columns);
  }
  .col__b3-3--last {
    @include span(3 of $columns last);
  }
  .col__b3-4 {
    @include span(4 of $columns);
  }
  .col__b3-4--last {
    @include span(4 of $columns last);
  }
  .col__b3-5 {
    @include span(5 of $columns);
  }
  .col__b3-5--last {
    @include span(5 of $columns last);
  }
  .col__b3-6 {
    @include span(6 of $columns);
  }
  .col__b3-6--last {
    @include span(6 of $columns last);
  }
  .col__b3-7 {
    @include span(7 of $columns);
  }
  .col__b3-7--last {
    @include span(7 of $columns last);
  }
  .col__b3-8 {
    @include span(8 of $columns);
  }
  .col__b3-8--last {
    @include span(8 of $columns last);
  }
  .col__b3-9 {
    @include span(9 of $columns);
  }
  .col__b3-9--last {
    @include span(9 of $columns last);
  }
}

// --------------------------------------------------------------------------
// 07. Breakpoint 4 (break-4) columns
// --------------------------------------------------------------------------

@include breakpoint(break-4) {
  .col__b4-3 {
    @include span(3 of $columns);
  }
  .col__b4-3--last {
    @include span(3 of $columns last);
  }
  .col__b4-4 {
    @include span(4 of $columns);
  }
  .col__b4-4--last {
    @include span(4 of $columns last);
  }
  .col__b4-5 {
    @include span(5 of $columns);
  }
  .col__b4-5--last {
    @include span(5 of $columns last);
  }
  .col__b4-6 {
    @include span(6 of $columns);
  }
  .col__b4-6--last {
    @include span(6 of $columns last);
  }
  .col__b4-7 {
    @include span(7 of $columns);
  }
  .col__b4-7--last {
    @include span(7 of $columns last);
  }
  .col__b4-8 {
    @include span(8 of $columns);
  }
  .col__b4-8--last {
    @include span(8 of $columns last);
  }
  .col__b4-9 {
    @include span(9 of $columns);
  }
  .col__b4-9--last {
    @include span(9 of $columns last);
  }
}

// --------------------------------------------------------------------------
// 08. Pushes - uncomment all needed pushing classes
// --------------------------------------------------------------------------

// NOTE: Pushes have to be declared after 03. Columns.
// NOTE: Make sure you reach all columns in each row, depending on the
// number of columns in your grid; e.g: push 0.5, span 11.5 (12 cols)

// .col__push--half {
//   @include push(0.5);
// }


// --------------------------------------------------------------------------
// 09. Special Columns - place various classes here
// --------------------------------------------------------------------------
