// --------------------------------------------------------------------------
// Base / Modifiers
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Background color modifier
// 02. Color modifier
// 03. Structural modifier
// 04. Link/Button modifier


// --------------------------------------------------------------------------
// 01. Background color modifier
// --------------------------------------------------------------------------

.-bg-black {
  background-color: $black;
}

.-bg-white {
  background-color: $white;
}

.-bg-alpha {
  background-color: $color-alpha;
}

.-bg-beta {
  background-color: $color-beta;
}

.-bg-grey-light {
  background-color: $color-grey-light;
}

.-bg-grey-medium {
  background-color: $color-grey-medium;
}

.-bg-grey-dark {
  background-color: $color-grey-dark;
}

.-section-grey {
  background-color: rgba($color-alpha, .05);
}

// --------------------------------------------------------------------------
// 02. Color modifier
// --------------------------------------------------------------------------

.-color-white {
  color: $white;
}

.-color-alpha {
  color: $color-alpha;
}

// --------------------------------------------------------------------------
// 03. Structural modifier
// --------------------------------------------------------------------------

.-padded {
  @include ms-padding($spacing-section-mobile zero, break-0);
  @include ms-padding(4 zero, break-1 to break-4);
}

.-padded-narrow {
  @include ms-padding(1 zero);
}

.-padded-wide {
  @include ms-padding($spacing-section-mobile zero, break-0);
  @include ms-padding($spacing-section zero, break-1 to break-4);
}

.-padded-wide--mod-bottom-only {
  @include ms-padding(zero zero $spacing-section-mobile, break-0);
  @include ms-padding(zero zero $spacing-section, break-1 to break-4);
}

.-padded-wide--mod-top-only {
  @include ms-padding($spacing-section-mobile zero zero, break-0);
  @include ms-padding($spacing-section zero zero, break-1 to break-4);
}

.--mod-mobile-bottom-only {
  &.-padded-wide {
    @include ms-padding(zero zero $spacing-section-mobile, break-0);
    @include ms-padding($spacing-section zero, break-1 to break-4);
  }

  &.-padded-wide--mod-top-only {
    @include ms-padding(zero, break-0);
    @include ms-padding($spacing-section zero zero, break-1 to break-4);
  }
}

.--mod-mobile-top-only {
  &.-padded-wide {
    @include ms-padding($spacing-section-mobile zero zero, break-0);
    @include ms-padding($spacing-section zero, break-1 to break-4);
  }

  &.-padded-wide--mod-bottom-only {
    @include ms-padding(zero, break-0);
    @include ms-padding(zero zero $spacing-section, break-1 to break-4);
  }
}

.--mod-mobile-none {
  &.-padded-wide {
    @include ms-padding(zero, break-0);
    @include ms-padding($spacing-section zero, break-1 to break-4);
  }

  &.-padded-wide--mod-top-only {
    @include ms-padding(zero, break-0);
    @include ms-padding($spacing-section zero zero, break-1 to break-4);
  }

  &.-padded-wide--mod-bottom-only {
    @include ms-padding(zero, break-0);
    @include ms-padding(zero zero $spacing-section, break-1 to break-4);
  }
}

.-max-width-limited {
  @include maxwidth;
  margin-left: auto;
  margin-right: auto;
}

.-indent-right > .col {
  @include breakpoint(break-2) {
    padding-right: span(4) + gutter();
  }
}

.-centered {
  text-align: center;
}

.col-wrapper.-top-margin-double {
  @include ms-margin-top($spacing-vertical-gutter-mobile * 2, break-0);
  @include ms-margin-top($spacing-vertical-gutter * 2, break-1 to break-4);

  &:first-child {
    margin-top: 0;
  }
}

.col-wrapper.-top-margin-section {
  @include ms-margin-top($spacing-section-mobile, break-0);
  @include ms-margin-top($spacing-section, break-1 to break-4);

  &:first-child {
    margin-top: 0;
  }
}

.col-wrapper.-bottom-margin-double {
  @include ms-margin-bottom($spacing-vertical-gutter-mobile * 2, break-0);
  @include ms-margin-bottom($spacing-vertical-gutter * 2, break-1 to break-4);

  &:last-child {
    margin-bottom: 0;
  }
}

.col-wrapper.-bottom-margin-section {
  @include ms-margin-bottom($spacing-section-mobile, break-0);
  @include ms-margin-bottom($spacing-section, break-1 to break-4);

  &:last-child {
    margin-bottom: 0;
  }
}

// --------------------------------------------------------------------------
// 04. Link/Button modifier
// --------------------------------------------------------------------------

.-btn-alpha {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: $white;
  @include font-weight-body(medium);
  @include ms-padding(-1 1);
  cursor: pointer;
  border-radius: $border-radius;
  border: 0 none;
  outline: none;
  background-color: $color-text-beta;
  box-shadow: inset 0 0 0 2px $color-text-beta;
  transition: box-shadow .25s $easing-alpha-out, background-color .25s $easing-alpha-out;

  &:visited {
    color: $white;
  }

  &:hover,
  &:active,
  &:focus {
    color: $white;
    box-shadow: inset 0 0 0 2px $color-beta;
    background-color: $color-beta;
  }

  @include breakpoint(break-1) {
    width: auto;
    text-align: left;
  }
}

.-btn-alpha.-inverted {
  box-shadow: inset 0 0 0 2px $color-beta;
  background-color: $color-beta;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-text-beta;
    box-shadow: inset 0 0 0 2px $color-text-beta;
  }
}
