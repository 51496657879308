// --------------------------------------------------------------------------
// Modules / Card
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-card {
  opacity: 0;
  overflow: visible;
  transition: opacity .5s $easing-alpha-out;

  &.swiper-container-horizontal,
  &.-no-swiper {
    opacity: 1;
  }
}

html .module-card.swiper-container {
  padding-bottom: gutter();
}

.module-card .cards__card {
  opacity: 1;
  position: relative;
  overflow: hidden;
  z-index: 2;
  box-shadow: $shadow-alpha;
}

.module-card .cards__card .card__image {
  position: relative;
  overflow: hidden;
  z-index: 2;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(76, 136, 190, 0) 0%, rgba(76, 136, 190, 0) 70%,rgba(76, 136, 190, 1) 90%);
  }


  & img {
    width: 100%;
    height: auto;
  }
}

.module-card .cards__card .card__image.-no-flow {

  &:after {
    background: none;
  }
}

.module-card .cards__card .card__content {
  position: absolute;
  left:0;
  bottom: 80px;
  z-index:5;
  @include ms-padding(2 3);
  background-color: transparent;

  & h3 {
    color: $white;
  }

  & p {
    color: $white;
  }
}

.module-card .cards__card .card__button span {
  display: block;
  position: relative;
  @include font-weight-body(bold);
  @include ms-padding(2 3);
  background-color: $white;

  &:after {
    position: absolute;
    @include ms-right(3);
    top: 50%;
    transform: translateY(-50%);
  }
}

.module-card .swiper-pagination {
  margin-bottom: -60px;
  padding-bottom: 30px;
  @include breakpoint(break-2) {
    display:none;
  }

}
