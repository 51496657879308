// --------------------------------------------------------------------------
// Partials / Page Header
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.page__topbar {
  position: relative;
  background-color: $white;
  z-index: 11;
}

.-nav-mobile .page__topbar {
  display: none;
}

.page__topbar .inner__wrap {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.page__header {
  position: absolute;
  width: 100%;
  display: flex;
  opacity: 0;
  align-items: center;
  background-color: $white;
  box-shadow: inset 0 -1px 0 0 rgba($color-alpha, .25);
  z-index: 10;
  transition: opacity .35s $easing-alpha-out;
}

.-headerloaded .page__header {
  opacity: 1;
}

.page__header.-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
  transition: transform .35s $easing-alpha-out;

  &:before {
    width: 100%;
    border-radius: 0;
  }
}

.page__header.hrjs--not-top.hrjs--unpinned {
  transform: translateY(-100%);
}

.page__header .inner__wrap {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 10;
}

.-nav-mobile .page__header .inner__wrap {
  @include ms-padding(0 zero);
  justify-content: space-between;
}

.module-logo {
  display: flex;
  align-items: center;
  z-index: 10;
  @include ms-padding-right(4);
}

.-nav-mobile .module-logo {
  padding: 0;
}

.module-logo svg {
  display: block;
}

.module-search {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 20;
  transition: right .35s $easing-alpha-out;
}

.-nav-desktop-cropped .page__header > .inner__wrap > .module-search,
.-nav-mobile .page__header > .inner__wrap > .module-search {
  display: none;
}

.module-search .input__field--input {
  display: flex;
  align-items: center;
}

.module-search .input__field--input > button {
  padding: 0 10px 0 0;
  border: none;

  @include ms-font-size(-1);
  @include font-weight-body(medium);
  color: $color-alpha;
}

.-search-opened .module-search .input__field--input > button {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding-right: 0;
}

.module-search .input__field--input > input {
  width: 0;
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid rgba($color-alpha, .25);
  outline: none;
  @include ms-font-size(-1);
  @include font-weight-body(medium);
  color: rgba($color-text-alpha, .65);
  transition: padding .35s $easing-alpha-out,  width .35s $easing-alpha-out;
}

.-search-opened .module-search .input__field--input > input {
  padding: 10px 10px 10px 25px;
  @include ms-margin-right(3);
}

.module-search .input__field--input > label {
  @include ms-font-size(-1);
  @include font-weight-body(medium);
  color: $color-alpha;
  overflow: hidden;
}

.-search-opened .module-search .input__field--input > label {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba($color-text-alpha, .65);
  pointer-events: none;
  animation: label-switch-in .5s $easing-alpha-out forwards;
}

.-search-closed .module-search .input__field--input > label {
  pointer-events: all;
  animation: label-switch-out .7s $easing-alpha-out forwards;
}

.module-search .close-icon {
  position: relative;
  width: 0;
  height: 0;
  -webkit-backface-visibility: hidden;
  opacity: 0;
  transition: opacity .1s $easing-alpha-out;
}

.module-search .close-icon .line {
  position: absolute;
  width: 100%;
  height: 2px;
  left: 6px;
  top: 6px;
  background-color: $color-alpha;
}

.module-search .close-icon .line:nth-child(1) {
  transform: translateX(-7px) translateY(0) rotate(45deg);
}

.module-search .close-icon .line:nth-child(2) {
  transform: translateX(-7px) translateY(0) rotate(-45deg);
}

.-search-opened .module-search .close-icon {
  width: 14px;
  height: 14px;
  opacity: 1;
  transition: opacity .35s $easing-alpha-out .35s;
}

@keyframes label-switch-in {
  0% { opacity: 0; text-indent: -600px; }
  50% { opacity: 1; text-indent: -600px; }
  100% { opacity: 1; text-indent: 0; }
}

@keyframes label-switch-out {
  0% { opacity: 0; text-indent: -600px; }
  50% { opacity: 1; text-indent: 0; }
  100% { opacity: 1; text-indent: 0; }
}

.module-nav-more-btn-back {
  display:  flex;
  align-items:  center;
  cursor: pointer;
}

.module-nav-more-btn-back .icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  box-shadow: 0 0 0 1px rgba($color-alpha, .25);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  @include ms-font-size(-1);
  color: $color-alpha;
}

.module-nav-more-btn-back .text {
  @include ms-font-size(-2);
  color: $color-alpha;
}
