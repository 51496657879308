// --------------------------------------------------------------------------
// Base / Layouts (based on cols module)
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Variables used in the multi column layouts
// 02. Multi column layouts (based on 12 column grid)


// --------------------------------------------------------------------------
// 01. Variables used in the multi column layouts
// --------------------------------------------------------------------------

$verticalGutter: $spacing-vertical-gutter;

// --------------------------------------------------------------------------
// 02. Multi column layouts (based on 12 column grid)
// --------------------------------------------------------------------------

// 2 column
// Dimension: 50% / 50%
// Breakpoint: break-1
.l-2col-b1-50-50 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b1-6;
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b1-6--last;
  }
}

.l-2col-b1-50-50.-max-width-limited.-breakout-left > .col-one {
  @include breakpoint(break-1) {
    padding-right: span(1) + gutter();
  }
}

.l-2col-b1-50-50.-max-width-limited.-breakout-right > .col-two {
  @include breakpoint(break-1) {
    padding-left: span(1) + gutter();
  }
}

.l-2col-b1-50-50--normal {
  > .col-one {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }
}

.l-2col-b1-50-50--inverted {
  flex-direction: column-reverse;

  @include breakpoint(break-1) {
    flex-direction: row;
  }

  > .col-one {
    @include ms-margin-bottom(zero, break-0);
  }

  > .col-two {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }
}

// 2 column
// Dimension: 50% / 50%
// Breakpoint: break-2
.l-2col-b2-50-50 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b2-6;
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b2-6--last;
  }
}

.l-2col-b2-50-50.-max-width-limited.-breakout-left > .col-one {
  @include breakpoint(break-2) {
    padding-right: span(1) + gutter();
  }
}

.l-2col-b2-50-50.-max-width-limited.-breakout-right > .col-two {
  @include breakpoint(break-2) {
    padding-left: span(1) + gutter();
  }
}

.l-2col-b2-50-50--normal {
  > .col-one {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }
}

.l-2col-b2-50-50--inverted {
  flex-direction: column-reverse;

  @include breakpoint(break-2) {
    flex-direction: row;
  }

  > .col-one {
    @include ms-margin-bottom(zero, break-0);
  }

  > .col-two {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }
}

// 2 column
// Dimension: 75% / 25%
// Breakpoint: break-1
.l-2col-b1-75-25 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b1-9;
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b1-3--last;
  }
}

.l-2col-b1-75-25--normal {
  > .col-one {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }
}

.l-2col-b1-75-25--inverted {
  flex-direction: column-reverse;

  @include breakpoint(break-1) {
    flex-direction: row;
  }

  > .col-one {
    @include ms-margin-bottom(zero, break-0);
  }

  > .col-two {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }
}

// 2 column
// Dimension: 75% / 25%
// Breakpoint: break-2
.l-2col-b2-75-25 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b2-9;
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b2-3--last;
  }
}

.l-2col-b2-75-25--normal {
  > .col-one {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }
}

.l-2col-b2-75-25--inverted {
  flex-direction: column-reverse;

  @include breakpoint(break-2) {
    flex-direction: row;
  }

  > .col-one {
    @include ms-margin-bottom(zero, break-0);
  }

  > .col-two {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }
}

// 2 column
// Dimension: 25% / 75%
// Breakpoint: break-1
.l-2col-b1-25-75 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b1-3;
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b1-9--last;
  }
}

.l-2col-b1-25-75--normal {
  > .col-one {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }
}

.l-2col-b1-25-75--inverted {
  flex-direction: column-reverse;

  @include breakpoint(break-1) {
    flex-direction: row;
  }

  > .col-one {
    @include ms-margin-bottom(zero, break-0);
  }

  > .col-two {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }
}

// 2 column
// Dimension: 25% / 75%
// Breakpoint: break-2
.l-2col-b2-25-75 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b2-3;
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b2-9--last;
  }
}

.l-2col-b2-25-75--normal {
  > .col-one {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }
}

.l-2col-b2-25-75--inverted {
  flex-direction: column-reverse;

  @include breakpoint(break-2) {
    flex-direction: row;
  }

  > .col-one {
    @include ms-margin-bottom(zero, break-0);
  }

  > .col-two {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }
}

// 2 column
// Dimension: 66% / 33%
// Breakpoint: break-1
.l-2col-b1-66-33 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b1-8;
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b1-4--last;
  }
}

.l-2col-b1-66-33--normal {
  > .col-one {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }
}

.l-2col-b1-66-33--inverted {
  flex-direction: column-reverse;

  @include breakpoint(break-1) {
    flex-direction: row;
  }

  > .col-one {
    @include ms-margin-bottom(zero, break-0);
  }

  > .col-two {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }
}

// 2 column
// Dimension: 66% / 33%
// Breakpoint: break-2
.l-2col-b2-66-33 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b2-8;
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b2-4--last;
  }
}

.l-2col-b2-66-33--normal {
  > .col-one {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }
}

.l-2col-b2-66-33--inverted {
  flex-direction: column-reverse;

  @include breakpoint(break-2) {
    flex-direction: row;
  }

  > .col-one {
    @include ms-margin-bottom(zero, break-0);
  }

  > .col-two {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }
}

// 2 column
// Dimension: 33% / 66%
// Breakpoint: break-1
.l-2col-b1-33-66 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b1-4;
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b1-8--last;
  }
}

.l-2col-b1-33-66--normal {
  > .col-one {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }
}

.l-2col-b1-33-66--inverted {
  flex-direction: column-reverse;

  @include breakpoint(break-1) {
    flex-direction: row;
  }

  > .col-one {
    @include ms-margin-bottom(zero, break-0);
  }

  > .col-two {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }
}

// 2 column
// Dimension: 33% / 66%
// Breakpoint: break-2
.l-2col-b2-33-66 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b2-4;
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b2-8--last;
  }
}

.l-2col-b2-33-66--normal {
  > .col-one {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }
}

.l-2col-b2-33-66--inverted {
  flex-direction: column-reverse;

  @include breakpoint(break-2) {
    flex-direction: row;
  }

  > .col-one {
    @include ms-margin-bottom(zero, break-0);
  }

  > .col-two {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }
}



// 2 column
// Dimension: 60% / 40%
// Breakpoint: break-1
.l-2col-b1-60-40 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b1-7;
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b1-5--last;
  }
}

.l-2col-b1-60-40--normal {
  > .col-one {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }
}

.l-2col-b1-60-40--inverted {
  flex-direction: column-reverse;

  @include breakpoint(break-1) {
    flex-direction: row;
  }

  > .col-one {
    @include ms-margin-bottom(zero, break-0);
  }

  > .col-two {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }
}

// 2 column
// Dimension: 60% / 40%
// Breakpoint: break-2
.l-2col-b2-60-40 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b2-7;
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b2-5--last;
  }
}

.l-2col-b2-60-40--normal {
  > .col-one {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }
}

.l-2col-b2-60-40--inverted {
  flex-direction: column-reverse;

  @include breakpoint(break-2) {
    flex-direction: row;
  }

  > .col-one {
    @include ms-margin-bottom(zero, break-0);
  }

  > .col-two {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }
}

// 2 column
// Dimension: 40% / 60%
// Breakpoint: break-1
.l-2col-b1-40-60 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b1-5;
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b1-7--last;
  }
}

.l-2col-b1-40-60--normal {
  > .col-one {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }
}

.l-2col-b1-40-60--inverted {
  flex-direction: column-reverse;

  @include breakpoint(break-1) {
    flex-direction: row;
  }

  > .col-one {
    @include ms-margin-bottom(zero, break-0);
  }

  > .col-two {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }
}

// 2 column
// Dimension: 40% / 60%
// Breakpoint: break-2
.l-2col-b2-40-60 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b2-5;
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b2-7--last;
  }
}

.l-2col-b2-40-60--normal {
  > .col-one {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }
}

.l-2col-b2-40-60--inverted {
  flex-direction: column-reverse;

  @include breakpoint(break-2) {
    flex-direction: row;
  }

  > .col-one {
    @include ms-margin-bottom(zero, break-0);
  }

  > .col-two {
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }
}

// 3 column
// Dimension: 33% / 33% / 33%
// Breakpoint: break-1
.l-3col-b1-33-33-33 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b1-4;

    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b1-4;

    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }

  > .col-three {
    @extend .col__b0-full;
    @extend .col__b1-4--last;
  }
}

// 3 column
// Dimension: 33% / 33% / 33%
// Breakpoint: break-2
.l-3col-b2-33-33-33 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b2-4;

    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b2-4;

    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }

  > .col-three {
    @extend .col__b0-full;
    @extend .col__b2-4--last;
  }
}

// 4 column
// Dimension: 25% / 25% / 25% / 25%
// Breakpoint: break-2, break-3
.l-4col-b2-b3-25-25-25-25 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b2-6;
    @extend .col__b3-3;

    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-3);
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b2-6--last;
    @extend .col__b3-3;

    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-3);
  }

  > .col-three {
    @extend .col__b0-full;
    @extend .col__b2-6;
    @extend .col__b3-3;

    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-2);
  }

  > .col-four {
    @extend .col__b0-full;
    @extend .col__b2-6--last;
    @extend .col__b3-3--last;
  }
}

// 4 column
// Dimension: 25% / 25% / 25% / 25%
// Breakpoint: break-1, break-3
.l-4col-b1-b3-25-25-25-25 {
  > .col-one {
    @extend .col__b0-full;
    @extend .col__b1-6;
    @extend .col__b3-3;

    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-3);
  }

  > .col-two {
    @extend .col__b0-full;
    @extend .col__b1-6--last;
    @extend .col__b3-3;

    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-3);
  }

  > .col-three {
    @extend .col__b0-full;
    @extend .col__b1-6;
    @extend .col__b3-3;

    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1);
  }

  > .col-four {
    @extend .col__b0-full;
    @extend .col__b1-6--last;
    @extend .col__b3-3--last;
  }
}
