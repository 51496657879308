// --------------------------------------------------------------------------
// Base / Content Item
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.content-item {
  @include ms-margin(zero zero $spacing-vertical-gutter-mobile, break-0);
  @include ms-margin(zero zero $spacing-vertical-gutter, break-1 to break-4);
}

.content-item:last-child {
  @include ms-margin-bottom(zero);
}

.col-wrapper--sidebar > .col-two > .content-item {
  @include breakpoint(break-2) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
