// --------------------------------------------------------------------------
// Partials / Page
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.page {
  position: relative;
  overflow: hidden;
}

.page__content .content__breadcrumb {
  @include ms-padding(0 zero);
  background-color: $white;
  box-shadow: inset 0 1px 0 0 rgba(0, 74, 121, 0.05);
  display: none;
  margin-bottom: 0;

  @include breakpoint(break-1) {
    display: block;
  }
}

.page__content .content__breadcrumb .col {
  display: none;
  margin-bottom: 0;

  @include breakpoint(break-1) {
    display: block;
  }
}
