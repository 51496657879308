// --------------------------------------------------------------------------
// Modules / Billboard
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles

// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-billboard {
  display: flex;
  justify-content: center;
  text-align: center;
  @include ms-padding($spacing-section-mobile + 5 zero $spacing-section-mobile, break-0);
  @include ms-padding($spacing-section + 5 zero $spacing-section, break-1 to break-4);
}

.module-billboard .billboard__content {
  width: span(10);

  @include breakpoint(break-1) {
    width: span(8);
  }

  @include breakpoint(break-1) {
    width: span(6);
  }
}

.module-billboard .content__link {
  @include ms-margin-top(2);
}
