////
/// Modular Scale - mixins for the typographic joy
/// @group ms
/// @author pd and dm
////


/// Adds a modular scale `margin` (_directional_) and can be used like the known
/// css `margin` with all the shortforms. It does output all margin values for
/// the defined `$breakpoints` based on the modular scale.
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys. The list can contain
/// different expressions of breakpoints. Have a look at the examples above.
///
/// @see $breakpoints
///
/// @example scss - Set all margins to initial modular scale entry (for all bps)
/// .foo {
///   @include ms-margin(0);
/// }
///
/// // Output for each breakpoint will look like:
///
/// // margin: 1em;
///
/// @example scss - Set all margins to first modular scale entry (for all bps)
/// .foo {
///   @include ms-margin(1);
/// }
///
/// // Output for each breakpoint will look like:
///
/// // Breakpoint: default
/// // Scale: Golden Ratio
/// // margin: 1.618em;
///
/// // Breakpoint: break-1
/// // Scale: Minor Third
/// // @media screen and (min-width: 40em) {
/// //   margin: 1.2em;
/// // }
///
/// // Breakpoint: break-2
/// // Scale: Major Third
/// // @media screen and (min-width: 65em) {
/// //   margin: 1.25em;
/// // }
///
/// // Breakpoint: break-3
/// // Scale: Perfect Fourth
/// // @media screen and (min-width: 90em) {
/// //   margin: 1.333em;
/// // }
///
/// @example scss - Set all margins to zero (for all bps)
/// .foo {
///   @include ms-margin(zero);
/// }
///
/// // Output for each breakpoint will look like:
/// // margin: 0;
///
/// @example scss - Let the bottom margin as is while changing the other ones (for all bps)
/// .foo {
///   @include ms-margin(1 2 null 4);
/// }
///
/// // Output for each breakpoint will look like:
///
/// // Breakpoint: default
/// // Scale: Golden Ratio
/// // margin-top: 1.618em;
/// // margin-right: 2.618em;
/// // margin-left: 6.854em;
///
/// // Breakpoint: break-1
/// // Scale: Minor Third
/// // @media screen and (min-width: 40em) {
/// //   margin-top: 1.2em;
/// //   margin-right: 1.44em;
/// //   margin-left: 2.074em;
/// // }
///
/// // Breakpoint: break-2
/// // Scale: Major Third
/// // @media screen and (min-width: 65em) {
/// //   margin-top: 1.25em;
/// //   margin-right: 1.563em;
/// //   margin-left: 2.441em;
/// // }
///
/// // Breakpoint: break-3
/// // Scale: Perfect Fourth
/// // @media screen and (min-width: 90em) {
/// //   margin-top: 1.333em;
/// //   margin-right: 1.777em;
/// //   margin-left: 3.157em;
/// // }
///
/// @example scss - Set the value explicitly for `all` breakpoints (`all` is the default)
/// .foo {
///   @include ms-margin(1 2 3 4, all);
/// }
///
/// // Output for each breakpoint will look like:
///
/// // Breakpoint: default
/// // Scale: Golden Ratio
/// // margin: 1.618em 2.618em 4.236em 6.854em;
///
/// // Breakpoint: break-1
/// // Scale: Minor Third
/// // @media screen and (min-width: 40em) {
/// //   margin: 1.2em 1.44em 1.728em 2.074em;
/// // }
///
/// // Breakpoint: break-2
/// // Scale: Major Third
/// // @media screen and (min-width: 65em) {
/// //   margin: 1.25em 1.563em 1.953em 2.441em;
/// // }
///
/// // Breakpoint: break-3
/// // Scale: Perfect Fourth
/// // @media screen and (min-width: 90em) {
/// //   margin: 1.333em 1.777em 2.369em 3.157em;
/// // }
///
/// @example scss - Set the value only for breakpoint one
/// .foo {
///   @include ms-margin(4, break-1);
/// }
///
/// // Output for each breakpoint will look like:
///
/// // Breakpoint: break-1
/// // Scale: Minor Third
/// // @media screen and (min-width: 40em) {
/// //   margin: 1.2em 1.44em 1.728em 2.074em;
/// // }
///
/// @example scss - Set the value for several breakpoints (break-1 break-3)
///  .foo {
///    @include ms-margin(4, break-1 break-3);
///  }
///
/// // Output for each breakpoint will look like:
///
/// // Breakpoint: break-1
/// // Scale: Minor Third
/// // @media screen and (min-width: 40em) {
/// //   margin: 1.2em 1.44em 1.728em 2.074em;
/// // }
///
/// // Breakpoint: break-3
/// // Scale: Perfect Fourth
/// // @media screen and (min-width: 90em) {
/// //   margin: 1.333em 1.777em 2.369em 3.157em;
/// // }
///
/// @example scss - Set the value from breakpoint one to breakpoint three.
///  .foo {
///    @include ms-margin(4, break-1 to break-3);
///  }
///
/// // Output for each breakpoint will look like:
///
/// // Breakpoint: break-1
/// // Scale: Minor Third
/// // @media screen and (min-width: 40em) {
/// //   margin: 1.2em 1.44em 1.728em 2.074em;
/// // }
///
/// // Breakpoint: break-2
/// // Scale: Major Third
/// // @media screen and (min-width: 65em) {
/// //   margin: 1.25em 1.563em 1.953em 2.441em;
/// // }
///
/// // Breakpoint: break-3
/// // Scale: Perfect Fourth
/// // @media screen and (min-width: 90em) {
/// //   margin: 1.333em 1.777em 2.369em 3.157em;
/// // }
///
/// @example scss - Set the value from breakpoint two to the last breakpoint.
///  .foo {
///    @include ms-margin(4, break-2 continue);
///  }
///
/// // Output for each breakpoint will look like:
///
/// // Breakpoint: break-2
/// // Scale: Major Third
/// // @media screen and (min-width: 65em) {
/// //   margin: 1.25em 1.563em 1.953em 2.441em;
/// // }
///
/// // Breakpoint: break-3
/// // Scale: Perfect Fourth
/// // @media screen and (min-width: 90em) {
/// //   margin: 1.333em 1.777em 2.369em 3.157em;
/// // }
///
@mixin ms-margin($vals, $bp-keys: all) {
  @include ms-breakpoints(margin, false, $vals, $bp-keys);
}

/// Adds a modular scale `margin-top`
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
/// @param {Boolean} $negative [false] - Return value negative or not
///
@mixin ms-margin-top($vals, $bp-keys: all, $negative: false) {
  @include ms-breakpoints(margin, top, $vals, $bp-keys, $directional: false, $negative: $negative);
}

/// Adds a modular scale `margin-right`
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
/// @param {Boolean} $negative [false] - Return value negative or not
///
@mixin ms-margin-right($vals, $bp-keys: all, $negative: false) {
  @include ms-breakpoints(margin, right, $vals, $bp-keys, $directional: false, $negative: $negative);
}

/// Adds a modular scale `margin-bottom`
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
/// @param {Boolean} $negative [false] - Return value negative or not
///
@mixin ms-margin-bottom($vals, $bp-keys: all, $negative: false) {
  @include ms-breakpoints(margin, bottom, $vals, $bp-keys, $directional: false, $negative: $negative);
}

/// Adds a modular scale `margin-left`
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
/// @param {Boolean} $negative [false] - Return value negative or not
///
@mixin ms-margin-left($vals, $bp-keys: all, $negative: false) {
  @include ms-breakpoints(margin, left, $vals, $bp-keys, $directional: false, $negative: $negative);
}

/// Adds a modular scale `padding` (_directional_)
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
///
@mixin ms-padding($vals, $bp-keys: all) {
  @include ms-breakpoints(padding, false, $vals, $bp-keys);
}

/// Adds a modular scale `padding-top`
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
///
@mixin ms-padding-top($vals, $bp-keys: all) {
  @include ms-breakpoints(padding, top, $vals, $bp-keys, $directional: false);
}

/// Adds a modular scale `padding-right`
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
///
@mixin ms-padding-right($vals, $bp-keys: all) {
  @include ms-breakpoints(padding, right, $vals, $bp-keys, $directional: false);
}

/// Adds a modular scale `padding-bottom`
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
///
@mixin ms-padding-bottom($vals, $bp-keys: all) {
  @include ms-breakpoints(padding, bottom, $vals, $bp-keys, $directional: false);
}

/// Adds a modular scale `padding-left`
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
///
@mixin ms-padding-left($vals, $bp-keys: all) {
  @include ms-breakpoints(padding, left, $vals, $bp-keys, $directional: false);
}

/// Adds a modular scale `padding-top` with pixel offset
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {String} $offset-value - Offset value in px
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
///
@mixin ms-padding-top-offset($vals, $offset-value, $bp-keys: all) {
  @include ms-breakpoints(padding, top, $vals, $bp-keys, $directional: false, $offset: $offset-value);
}

/// Adds a modular scale `padding-bottom` with pixel offset
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {String} $offset-value - Offset value in px
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
///
@mixin ms-padding-bottom-offset($vals, $offset-value, $bp-keys: all) {
  @include ms-breakpoints(padding, bottom, $vals, $bp-keys, $directional: false, $offset: $offset-value);
}

/// Adds a modular scale `border-width` (_directional_)
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
///
@mixin ms-border-width($vals, $bp-keys: all) {
  @include ms-breakpoints(border, width, $vals, $bp-keys);
}

/// Adds a modular scale `font-size` (_directional_)
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
///
@mixin ms-font-size($vals, $bp-keys: all) {
  @include ms-breakpoints(font, size, $vals, $bp-keys, $directional: false);
}

/// Adds a modular scale `height` (_directional_)
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
///
@mixin ms-height($vals, $bp-keys: all) {
  @include ms-breakpoints(height, false, $vals, $bp-keys, $directional: false);
}

/// Adds a modular scale position `top` (_directional_)
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
/// @param {Boolean} $negative [false] - Return value negative or not
///
@mixin ms-top($vals, $bp-keys: all, $negative: false) {
  @include ms-breakpoints(top, false, $vals, $bp-keys, $directional: false, $negative: $negative);
}

/// Adds a modular scale position `top` (_directional_)
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
/// @param {Boolean} $negative [false] - Return value negative or not
///
@mixin ms-right($vals, $bp-keys: all, $negative: false) {
  @include ms-breakpoints(right, false, $vals, $bp-keys, $directional: false, $negative: $negative);
}

/// Adds a modular scale position `bottom` (_directional_)
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
/// @param {Boolean} $negative [false] - Return value negative or not
///
@mixin ms-bottom($vals, $bp-keys: all, $negative: false) {
  @include ms-breakpoints(bottom, false, $vals, $bp-keys, $directional: false, $negative: $negative);
}

/// Adds a modular scale position `left` (_directional_)
///
/// For examples see `ms-margin`
///
/// @param {List} $vals - Values
/// @param {List} $bp-keys [all] - List of Breakpoint Keys
/// @param {Boolean} $negative [false] - Return value negative or not
///
@mixin ms-left($vals, $bp-keys: all, $negative: false) {
  @include ms-breakpoints(left, false, $vals, $bp-keys, $directional: false, $negative: $negative);
}

/// Outputs the directional properties
///
/// @access private
/// @param {String} $pre - Prefix e.g. `margin`
/// @param {String} $suf - Suffix
/// @param {Number} $ratio - Modular scale ratio (https://github.com/at-import/modular-scale)
/// @param {List} $vals - List of values
///
/// @output Directional properties for the given values
///
/// @link http://github.com/at-import/modular-scale
///
@mixin ms-directional-property($pre, $suf, $ratio, $vals) {
  // Get the list of values
  $vals: ms-collapse-directionals($ratio, $vals);

  // Property Names
  $top:    $pre + "-top"    + if($suf, "-#{$suf}", "");
  $bottom: $pre + "-bottom" + if($suf, "-#{$suf}", "");
  $left:   $pre + "-left"   + if($suf, "-#{$suf}", "");
  $right:  $pre + "-right"  + if($suf, "-#{$suf}", "");
  $all:    $pre +             if($suf, "-#{$suf}", "");

  // Output directional properties if anything is falsy
  @if ms-contains-falsy($vals) {
    @if nth($vals, 1) { #{$top}: nth($vals, 1); }

    @if length($vals) == 1 {
      @if nth($vals, 1) { #{$right}: nth($vals, 1); }
    } @else {
      @if nth($vals, 2) { #{$right}: nth($vals, 2); }
    }

    // prop: top/bottom right/left
    @if length($vals) == 2 {
      @if nth($vals, 1) { #{$bottom}: nth($vals, 1); }
      @if nth($vals, 2) { #{$left}: nth($vals, 2); }

      // prop: top right/left bottom
    } @else if length($vals) == 3 {
      @if nth($vals, 3) { #{$bottom}: nth($vals, 3); }
      @if nth($vals, 2) { #{$left}: nth($vals, 2); }

      // prop: top right bottom left
    } @else if length($vals) == 4 {
      @if nth($vals, 3) { #{$bottom}: nth($vals, 3); }
      @if nth($vals, 4) { #{$left}: nth($vals, 4); }
    }

    // Else output top/right/bottom/left
  } @else {
    #{$all}: $vals;
  }
}


/// Outputs the non directional properties
///
/// @access private
/// @param {String} $pre - Prefix e.g. `margin`
/// @param {String} $suf - Suffix
/// @param {Number} $ratio - Modular scale ratio (https://github.com/at-import/modular-scale)
/// @param {Number} $val - Value
/// @param {String} $offset - Offset in px
/// @param {Boolean} $negative [false] - Return value negative or not
///
/// @output Non directional properties for the given value
///
/// @link http://github.com/at-import/modular-scale
/// @requires ms-get-value
///
@mixin ms-non-directional-property($pre, $suf, $ratio, $val, $offset, $negative: false) {
  // Set multiplier
  $multiplier: 1;
  @if $negative {
    $multiplier: -1;
  }

  @if $suf == false {
    $base: $ms-base;
    #{$pre}: ms-get-value($val, $base, $ratio) * $multiplier;
  } @else {
    $base: $ms-base;
    #{$pre}-#{$suf}: (ms-get-value($val, $base, $ratio) + em($offset)) * $multiplier;
  }
}

/// Outputs the given set of parameters accross the given list of `$bp-keys`.
///
/// @access private
///
/// @param {String} $pre - Prefix e.g. `margin`
/// @param {String} $suf - Suffix e.g. `right` or `left`
/// @param {List} $vals - Values
/// @param {String|List} $bp-keys - List of breakpoint keys or `all` for usage of
/// default `$breakpoints`
/// @param {Boolean} $directional [true] - Directional or not
/// @param {String} $offset [0] - Offset in pixel
/// @param {Boolean} $negative [false] - Return value negative or not
///
@mixin ms-breakpoints($pre, $suf, $vals, $bp-keys, $directional: true, $offset: 0, $negative: false) {
  @if not $breakpoints and not $modular-scale {
    @warn 'Please define a global breakpoints and modular scale map in your config.';
  } @else {
    // parse the given bp keys
    $bp-keys-list: parse-bp-keys-list($bp-keys);

    // iterate the given bp keys
    $ratio-before: 0;
    $bp-key-before: '';
    @each $bp-key in $bp-keys-list {
      // check if key is available in global map
      @if map-has-key($breakpoints, $bp-key) {
        // get ratio
        $ratio: ms-get-scale-ratio(str_slice($bp-key, 7));
        @if $ratio != $ratio-before {
          // output the content
          @include breakpoint($break: $bp-key, $bps: $breakpoints) {
            @if $directional {
              @include ms-directional-property($pre, $suf, $ratio, $vals);
            }
            @else {
              @include ms-non-directional-property($pre, $suf, $ratio, $vals, $offset, $negative: $negative);
            }
          }
        }
        $ratio-before: ms-get-scale-ratio(str_slice($bp-key, 7));
        $bp-key-before: $bp-key;
      } @else {
        @warn 'Breakpoint key #{$bp-key} not found. Skipped.';
      }
    }
  }
}
