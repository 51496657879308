// --------------------------------------------------------------------------
// Base / Forms
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. General Setup
// A. Form Grid
// B. Form Fields


// --------------------------------------------------------------------------
// 01. General Setup
// --------------------------------------------------------------------------

form,
.form {
  margin: 0;

  // --------------------------------------------------------------------------
  // A. Form Grid
  // --------------------------------------------------------------------------

  & .page__wrap + .page__wrap {
    @include ms-margin-top(6);
  }

  & .page__wrap .headline__wrap {
    @include ms-margin-bottom(0);
  }

  & .page__wrap .error__wrap {
    padding: 16px 26px 15px;
    @include ms-margin-bottom(-1);
    text-align: center;
  }

  & .field__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include ms-margin-bottom(-1);

    &:last-child {
      margin-bottom: 0;
    }
  }

  & .field__wrap.l-2col-b1-25-75 > .col-one,
  & .field__wrap.l-2col-b1-75-25 > .col-one,
  & .field__wrap.l-2col-b1-33-66 > .col-one,
  & .field__wrap.l-2col-b1-50-50 > .col-one,
  & .field__wrap.l-2col-b1-66-33 > .col-one {
    margin-bottom: 0;
  }

  & .field__wrap.l-2col-b1-25-75 > .col-two,
  & .field__wrap.l-2col-b1-75-25 > .col-two,
  & .field__wrap.l-2col-b1-33-66 > .col-two,
  & .field__wrap.l-2col-b1-50-50 > .col-two,
  & .field__wrap.l-2col-b1-66-33 > .col-two {
    @include ms-margin-top(-1, break-0);
    @include ms-margin-top(zero, break-1 to break-4);
  }

  & .actions {
    @include ms-margin-top(4);
  }

  // --------------------------------------------------------------------------
  // B. Form Fields
  // --------------------------------------------------------------------------

  input::-ms-clear {
    display: none;
  }

  select::-ms-expand {
    display: none;
  }

  & .form-group .input {
    position: relative;
    display: block;
    background-color: $white;
    border: 0 none;
    border-radius: $border-radius;
    @include ms-margin-bottom(1);

    & input,
    & textarea,
    & select {
      display: block;
      width: 100%;
      min-height: calc(1.8em + 32px);
      padding: 16px 26px;
      font: inherit;
      background-color: transparent;
      border: 0 none;
      box-shadow: none;
      outline: none;
    }

    & select {
      -webkit-appearance: none;
    }

    & input,
    & textarea,
    & select {
      &:focus + .input-background,
      &.-filled + .input-background {
        & > label {
          top: 0;
          transform: translateY(-50%) scale(.75);
          color: rgba($color-border, .9);
        }
      }

      &:focus + .input-background {
        box-shadow: inset 0 0 0 2px $color-border;

        & > label {
          color: $color-border;
        }
      }
    }

    & .input-background {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: $border-radius;
      box-shadow: inset 0 0 0 1px $color-border;
      transition: box-shadow .25s $easing-alpha;
      pointer-events: none;

      & label {
        position: absolute;
        top: 18px;
        left: 26px;
        transform: translateY(0);
        transform-origin: 0 50%;
        display: block;
        color: $color-border;
        background-color: $white;
        border-radius: $border-radius;
        box-shadow: -10px 0 0 0 $white, 10px 0 0 0 $white;
        transition: top .25s $easing-alpha, transform .25s $easing-alpha;
      }
    }
  }

  & .form-group.-error .input .input-background {
    background-color: $color-error-background;
    box-shadow: inset 0 0 0 1px $color-error-border;

    & label {
      color: $color-error;
      background-color: transparent;
      box-shadow: -10px 0 0 0 transparent, 10px 0 0 0 transparent;
    }
  }

  & .form-group.-error .input input:focus + .input-background,
  & .form-group.-error .input textarea:focus + .input-background,
  & .form-group.-error .input select:focus + .input-background {
    box-shadow: inset 0 0 0 2px $color-error-border;

    & > label {
      color: $color-error;
      background-color: $white;
      box-shadow: -10px 0 0 0 $white, 10px 0 0 0 $white;
    }
  }

  & .form-group .checkbox label.form-check-label {
    position: relative;
    display: block;
  }

  & .form-group .checkbox label.form-check-label:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 26px;
    height: 26px;
    content: '';
    background-color: $white;
    border: 1px solid $color-border;
    border-radius: $border-radius;
  }

  & .form-group.-error .checkbox label.form-check-label:before {
    background-color: $color-error-background;
    border: 1px solid $color-error-border;
  }

  & .form-group .checkbox label.form-check-label:after {
    position: absolute;
    top: 5px;
    left: 5px;
    transform: scale(0);
    width: 18px;
    height: 18px;
    @include icomoon('\e909');
    color: $color-border;
    opacity: 0;
    transition: transform .2s $easing-alpha, opacity .2s $easing-alpha;

    @include breakpoint(break-2) {
      top: 4px;
      left: 4px;
    }
  }

  & .form-group .checkbox.-checked label.form-check-label:after {
    transform: scale(1);
    opacity: 1;
  }

  & .form-group .checkbox label.form-check-label input {
    position: absolute;
    top: 1px;
    left: 1px;
    opacity: 0;
  }

  & .form-group .checkbox label.form-check-label span {
    display: block;
    padding: 0 0 0 44px;
  }
}

.no-touchevents {
  & form .form-group label.select-label,
  & .form .form-group label.select-label {
    pointer-events: none;
  }
}

.form-group.-error .error.help-block {
  display: block;
  @include ms-font-size(-2);
  color: $color-error;
  margin-top: 3px;
}

.selectbox {
  position: relative;
  display: block;

  &:after {
    color: $color-border;
    position: absolute;
    top: calc(35% + 5px);
    @include ms-right(2);
    @include icomoon('\e901');
    pointer-events: none;
    @include ms-font-size(-2);
    background-color: $white;
    padding-left: 10px;
  }

}
