// --------------------------------------------------------------------------
// Modules / Nav
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 00. Variables / Colors
// 01. Main
// A. Main - Mobile
// B. Main - Desktop
// C. Main - More
// 02. Language
// 03. Meta
// 04. Footer
// 05. Legal
// 06. Breadcrumb


// --------------------------------------------------------------------------
// 00. Variables / Colors
// --------------------------------------------------------------------------

$color-navitem-one: rgb(0, 74, 121);
$color-navitem-two: $color-navitem-one;
$color-navitem-three: $color-navitem-one;
$color-navitem-four: $color-navitem-one;
$color-navitem-five: $color-navitem-one;
$color-navitem-six: $color-navitem-one;

// --------------------------------------------------------------------------
// 01. Main
// --------------------------------------------------------------------------

.module-nav--main {
  display: none;
  z-index: 10;
}

.module-nav--main .nav__hamburger {
  display: none;
}

// --------------------------------------------------------------------------
// A. Main - Mobile
// --------------------------------------------------------------------------

.-nav-mobile .module-nav--more {
  -webkit-overflow-scrolling: touch;
}

.-nav-mobile .module-nav--more .nav__container {
  position: relative;
  z-index: 2;
  overflow-x: hidden;
  transition: transform .35s $easing-alpha-out;
}

.-nav-mobile .module-nav--more .nav__container.-submenu {
  transform: translateX(-100%);
  overflow-x: visible;
}

.-nav-mobile .module-nav--more .module-nav__lvl1 {
  overflow: visible;
}

.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item.module-nav__item--hasSubpages > a:after {
  display: none;
}

.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item > ul {
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  max-height: none;
  transition: none;
  padding: 1.354em 0;
}

.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item.-opened > ul {
  opacity: 1;
}

.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item .module-nav__lvl2 > .module-nav__item {
  opacity: 1;
  transform: none;
  transition: none;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.-nav-mobile .module-nav--more .module-nav__lvl2 > .module-nav__item > a {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.72em 1em;
  cursor: pointer;

  & span {
    display: block;
    @include ms-font-size(-1);
    @include font-weight-body(medium);
    color: rgba($color-text-alpha, .65);
  }
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--1 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a span {
  color: $color-navitem-one;
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--2 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a span {
  color: $color-navitem-two;
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--3 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a span {
  color: $color-navitem-three;
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--4 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a span {
  color: $color-navitem-four;
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--5 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a span {
  color: $color-navitem-five;
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--6 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a span {
  color: $color-navitem-six;
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--1 > .module-nav__lvl2 > .module-nav__item.module-nav__item--current > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-one;

  & span {
    color: $color-navitem-one;
  }
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--2 > .module-nav__lvl2 > .module-nav__item.module-nav__item--current > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-two;

  & span {
    color: $color-navitem-two;
  }
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--3 > .module-nav__lvl2 > .module-nav__item.module-nav__item--current > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-three;

  & span {
    color: $color-navitem-three;
  }
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--4 > .module-nav__lvl2 > .module-nav__item.module-nav__item--current > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-four;

  & span {
    color: $color-navitem-four;
  }
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--5 > .module-nav__lvl2 > .module-nav__item.module-nav__item--current > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-five;

  & span {
    color: $color-navitem-five;
  }
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--6 > .module-nav__lvl2 > .module-nav__item.module-nav__item--current > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-six;

  & span {
    color: $color-navitem-six;
  }
}

.-nav-mobile .module-nav--more .module-nav__lvl2 > .module-nav__item.module-nav__item--active:not(.module-nav__item--current) > a {
  box-shadow: none !important;
}

.-nav-mobile .module-nav--more .module-nav__lvl2 > .module-nav__item > ul {
  display: block;
  opacity: 1;
  max-height: none;
  transition: none;
}

.-nav-mobile .module-nav--more .module-nav__lvl2 > .module-nav__item .module-nav__lvl3 > .module-nav__item {
  opacity: 1;
  transform: none;
  transition: none;

  &:first-child {
    margin-top: 0.22em;
  }

  &:last-child {
    margin-bottom: 0.22em;
  }
}

.-nav-mobile .module-nav--more .module-nav__lvl3 > .module-nav__item > a {
  display: block;
  padding: 0.5em 1em;
  border: 0 none;
  background-color: transparent;
  box-shadow: none;

  & span {
    display: block;
    @include ms-font-size(-1);
    @include font-weight-body(medium);
    color: rgba($color-text-alpha, .65);
  }

  &:hover span {
    color: $color-alpha;
  }
}

.-nav-mobile .module-nav--more .module-nav__lvl3 > .module-nav__item.module-nav__item--active > a {
  box-shadow: inset 4px 0 0 0 $color-alpha;

  & span {
    color: $color-alpha;
  }
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--1 > .module-nav__lvl2 > .module-nav__item .module-nav__lvl3 > .module-nav__item > a:hover span {
  color: $color-navitem-one;
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--1 > .module-nav__lvl2 > .module-nav__item .module-nav__lvl3 > .module-nav__item.module-nav__item--active > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-one;

  & span {
    color: $color-navitem-one;
  }
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--2 > .module-nav__lvl2 > .module-nav__item .module-nav__lvl3 > .module-nav__item > a:hover span {
  color: $color-navitem-two;
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--2 > .module-nav__lvl2 > .module-nav__item .module-nav__lvl3 > .module-nav__item.module-nav__item--active > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-two;

  & span {
    color: $color-navitem-two;
  }
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--3 > .module-nav__lvl2 > .module-nav__item .module-nav__lvl3 > .module-nav__item > a:hover span {
  color: $color-navitem-three;
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--3 > .module-nav__lvl2 > .module-nav__item .module-nav__lvl3 > .module-nav__item.module-nav__item--active > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-three;

  & span {
    color: $color-navitem-three;
  }
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--4 > .module-nav__lvl2 > .module-nav__item .module-nav__lvl3 > .module-nav__item > a:hover span {
  color: $color-navitem-four;
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--4 > .module-nav__lvl2 > .module-nav__item .module-nav__lvl3 > .module-nav__item.module-nav__item--active > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-four;

  & span {
    color: $color-navitem-four;
  }
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--5 > .module-nav__lvl2 > .module-nav__item .module-nav__lvl3 > .module-nav__item > a:hover span {
  color: $color-navitem-five;
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--5 > .module-nav__lvl2 > .module-nav__item .module-nav__lvl3 > .module-nav__item.module-nav__item--active > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-five;

  & span {
    color: $color-navitem-five;
  }
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--6 > .module-nav__lvl2 > .module-nav__item .module-nav__lvl3 > .module-nav__item > a:hover span {
  color: $color-navitem-six;
}

.-nav-mobile .module-nav--more .module-nav__item.module-nav__item--6 > .module-nav__lvl2 > .module-nav__item .module-nav__lvl3 > .module-nav__item.module-nav__item--active > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-six;

  & span {
    color: $color-navitem-six;
  }
}

.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item,
.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item > a,
.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item > ul,
.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item > ul > li,
.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item > ul > li > a,
.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item > ul > li > ul,
.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item > ul > li > ul > li,
.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item > ul > li > ul > li > a {
  position: relative;
  z-index: 2;
}

.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item.-opened,
.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item.-opened > ul,
.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item.-opened > ul > li,
.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item.-opened > ul > li > a,
.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item.-opened > ul > li > ul,
.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item.-opened > ul > li > ul > li,
.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item.-opened > ul > li > ul > li > a {
  position: relative;
  z-index: 100;
}

.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item > ul,
.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item.-opened > ul {
  position: absolute;
}

.-nav-mobile .module-nav--more .module-nav__lvl1 > .module-nav__item.-opened > ul {
  overflow-x: hidden;
  overflow-y: auto;
}

// --------------------------------------------------------------------------
// B. Main - Desktop
// --------------------------------------------------------------------------

.module-nav--main {
  display: block;
  position: relative;
  height: 100%;
}

.-nav-mobile .module-nav--main {
  display: none;
}

.-search-opened .module-nav--main {
  pointer-events: none;
}

.module-nav--main.-hidden {
  display: none;
}

// Lvl 1
.module-nav--main ul,
.module-nav--main ul li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.module-nav--main .module-nav__lvl1 {
  width: 400vw;
  top: 0;
}

.module-nav--main .module-nav__lvl1 > .module-nav__item {
  display: inline-block;
}

.module-nav--main .module-nav__lvl1 > .module-nav__item > a {
  display: block;
  @include ms-padding(4 0);

  &:before {
    display: none;
  }

  &:after {
    display: none;
  }

  & span {
    @include ms-font-size(-1);
    @include font-weight-body(medium);
    color: rgba($color-text-alpha, .65);
  }
}

.no-touchevents .module-nav--main .module-nav__lvl1 > .module-nav__item > a {
  & span {
    transition: color .35s $easing-alpha-out;
  }
}

.module-nav--main .module-nav__lvl1 > .module-nav__item.module-nav__item--active > a {

  & > span {
    color: $color-alpha;
  }
}

.no-touchevents .module-nav--main .module-nav__lvl1:hover > .module-nav__item:hover > a > span,
.touchevents .module-nav--main .module-nav__lvl1 > .module-nav__item.-opened > a > span {
  color: $color-alpha;
}


.module-nav--main .module-nav__lvl1 > .module-nav__item.module-nav__item--1.module-nav__item--active > a {

  & > span {
    color: $color-navitem-one;
  }
}

.no-touchevents .module-nav--main .module-nav__lvl1:hover > .module-nav__item.module-nav__item--1:hover > a > span,
.touchevents .module-nav--main .module-nav__lvl1 > .module-nav__item.module-nav__item--1.-opened > a > span {
  color: $color-navitem-one;
}

.module-nav--main .module-nav__lvl1 > .module-nav__item.module-nav__item--2.module-nav__item--active > a {

  & > span {
    color: $color-navitem-two;
  }
}

.no-touchevents .module-nav--main .module-nav__lvl1:hover > .module-nav__item.module-nav__item--2:hover > a > span,
.touchevents .module-nav--main .module-nav__lvl1 > .module-nav__item.module-nav__item--2.-opened > a > span {
  color: $color-navitem-two;
}

.module-nav--main .module-nav__lvl1 > .module-nav__item.module-nav__item--3.module-nav__item--active > a {

  & > span {
    color: $color-navitem-three;
  }
}

.no-touchevents .module-nav--main .module-nav__lvl1:hover > .module-nav__item.module-nav__item--3:hover > a > span,
.touchevents .module-nav--main .module-nav__lvl1 > .module-nav__item.module-nav__item--3.-opened > a > span {
  color: $color-navitem-three;
}

.module-nav--main .module-nav__lvl1 > .module-nav__item.module-nav__item--4.module-nav__item--active > a {

  & > span {
    color: $color-navitem-four;
  }
}

.no-touchevents .module-nav--main .module-nav__lvl1:hover > .module-nav__item.module-nav__item--4:hover > a > span,
.touchevents .module-nav--main .module-nav__lvl1 > .module-nav__item.module-nav__item--4.-opened > a > span {
  color: $color-navitem-four;
}

.module-nav--main .module-nav__lvl1 > .module-nav__item.module-nav__item--5.module-nav__item--active > a {
  box-shadow: inset 0 -4px 0 0 $color-navitem-five;

  & > span {
    color: $color-navitem-five;
  }
}

.no-touchevents .module-nav--main .module-nav__lvl1:hover > .module-nav__item.module-nav__item--5:hover > a > span,
.touchevents .module-nav--main .module-nav__lvl1 > .module-nav__item.module-nav__item--5.-opened > a > span {
  color: $color-navitem-five;
}

.module-nav--main .module-nav__lvl1 > .module-nav__item.module-nav__item--6.module-nav__item--active > a {
  box-shadow: inset 0 -4px 0 0 $color-navitem-six;

  & > span {
    color: $color-navitem-six;
  }
}

.no-touchevents .module-nav--main .module-nav__lvl1:hover > .module-nav__item.module-nav__item--6:hover > a > span,
.touchevents .module-nav--main .module-nav__lvl1 > .module-nav__item.module-nav__item--6.-opened > a > span {
  color: $color-navitem-six;
}

.no-touchevents .module-nav--main .module-nav__lvl1 > .module-nav__item:hover > ul,
.touchevents .module-nav--main .module-nav__lvl1 > .module-nav__item.-opened > ul {
  opacity: 1;
  max-height: none;
  transform-origin: 20% 0;
  animation: zoomIn .2s;
}

// Lvl 2
.module-nav--main .module-nav__lvl1 > .module-nav__item > ul {
  position: absolute;
  top: calc(100% - 1px);
  text-align: left;
  overflow: hidden;
  z-index: 25;
  background-color: $white;
  box-shadow: 0 15px 20px 0 rgba($color-alpha, .25);
  display: block;
  opacity: 0;
  max-height: 0;
}


.module-nav--main .module-nav__lvl2 > .module-nav__item {
  opacity: 1;
  transform: translateX(0);
}

.module-nav--main .module-nav__lvl1 > .module-nav__item:hover .module-nav__lvl2 > .module-nav__item:nth-child(2),
.module-nav--main .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(2) {
  transition-delay: .05s;
}
.module-nav--main .module-nav__lvl1 > .module-nav__item:hover .module-nav__lvl2 > .module-nav__item:nth-child(3),
.module-nav--main .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(3) {
  transition-delay: .1s;
}
.module-nav--main .module-nav__lvl1 > .module-nav__item:hover .module-nav__lvl2 > .module-nav__item:nth-child(4),
.module-nav--main .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(4) {
  transition-delay: .15s;
}
.module-nav--main .module-nav__lvl1 > .module-nav__item:hover .module-nav__lvl2 > .module-nav__item:nth-child(5),
.module-nav--main .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(5) {
  transition-delay: .2s;
}
.module-nav--main .module-nav__lvl1 > .module-nav__item:hover .module-nav__lvl2 > .module-nav__item:nth-child(6),
.module-nav--main .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(6) {
  transition-delay: .25s;
}
.module-nav--main .module-nav__lvl1 > .module-nav__item:hover .module-nav__lvl2 > .module-nav__item:nth-child(7),
.module-nav--main .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(7) {
  transition-delay: .3s;
}
.module-nav--main .module-nav__lvl1 > .module-nav__item:hover .module-nav__lvl2 > .module-nav__item:nth-child(8),
.module-nav--main .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(8) {
  transition-delay: .35s;
}
.module-nav--main .module-nav__lvl1 > .module-nav__item:hover .module-nav__lvl2 > .module-nav__item:nth-child(9),
.module-nav--main .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(9) {
  transition-delay: .4s;
}
.module-nav--main .module-nav__lvl1 > .module-nav__item:hover .module-nav__lvl2 > .module-nav__item:nth-child(10),
.module-nav--main .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(10) {
  transition-delay: .45s;
}

.module-nav--main .module-nav__lvl2 > .module-nav__item > a {
  position: relative;
  display: block;
  @include ms-padding-left(4);
  @include ms-padding-right(7);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: transparent;
  transition: background-color .35s $easing-alpha-out;

  & span {
    @include ms-font-size(-1);
    @include font-weight-body(medium);
    color: rgba($color-text-alpha, .65);
    transition: color .2s $easing-alpha-out;
  }
}

.no-touchevents .module-nav--main .module-nav__lvl2 > .module-nav__item > a {
  &:hover,
  &:active,
  &:focus {
    background-color: rgba($color-alpha, .05);

    & span {
      color: $color-alpha;
    }
  }
}

.module-nav--main .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a {
  background-color: rgba($color-alpha, .05);

  & span {
    color: $color-alpha;
  }
}

.no-touchevents .module-nav--main .module-nav__item.module-nav__item--1 > .module-nav__lvl2 > .module-nav__item > a {
  &:hover,
  &:active,
  &:focus {
    background-color: rgba($color-navitem-one, .05);

    & span {
      color: $color-navitem-one;
    }
  }
}

.module-nav--main .module-nav__item.module-nav__item--1 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a {
  background-color: rgba($color-navitem-one, .05);

  & span {
    color: $color-navitem-one;
  }
}

.no-touchevents .module-nav--main .module-nav__item.module-nav__item--2 > .module-nav__lvl2 > .module-nav__item > a {
  &:hover,
  &:active,
  &:focus {
    background-color: rgba($color-navitem-two, .05);

    & span {
      color: $color-navitem-two;
    }
  }
}

.module-nav--main .module-nav__item.module-nav__item--2 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a {
  background-color: rgba($color-navitem-two, .05);

  & span {
    color: $color-navitem-two;
  }
}

.no-touchevents .module-nav--main .module-nav__item.module-nav__item--3 > .module-nav__lvl2 > .module-nav__item > a {
  &:hover,
  &:active,
  &:focus {
    background-color: rgba($color-navitem-three, .05);

    & span {
      color: $color-navitem-three;
    }
  }
}

.module-nav--main .module-nav__item.module-nav__item--3 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a {
  background-color: rgba($color-navitem-three, .05);
  box-shadow: inset 4px 0 0 0 $color-navitem-three;

  & span {
    color: $color-navitem-three;
  }
}

.no-touchevents .module-nav--main .module-nav__item.module-nav__item--4 > .module-nav__lvl2 > .module-nav__item > a {
  &:hover,
  &:active,
  &:focus {
    background-color: rgba($color-navitem-four, .05);

    & span {
      color: $color-navitem-four;
    }
  }
}

.module-nav--main .module-nav__item.module-nav__item--4 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a {
  background-color: rgba($color-navitem-four, .05);
  box-shadow: inset 4px 0 0 0 $color-navitem-four;

  & span {
    color: $color-navitem-four;
  }
}

.no-touchevents .module-nav--main .module-nav__item.module-nav__item--5 > .module-nav__lvl2 > .module-nav__item > a {
  &:hover,
  &:active,
  &:focus {
    background-color: rgba($color-navitem-five, .05);

    & span {
      color: $color-navitem-five;
    }
  }
}

.module-nav--main .module-nav__item.module-nav__item--5 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a {
  background-color: rgba($color-navitem-five, .05);
  box-shadow: inset 4px 0 0 0 $color-navitem-five;

  & span {
    color: $color-navitem-five;
  }
}

.no-touchevents .module-nav--main .module-nav__item.module-nav__item--6 > .module-nav__lvl2 > .module-nav__item > a {
  &:hover,
  &:active,
  &:focus {
    background-color: rgba($color-navitem-six, .05);

    & span {
      color: $color-navitem-six;
    }
  }
}

.module-nav--main .module-nav__item.module-nav__item--6 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a {
  background-color: rgba($color-navitem-six, .05);
  box-shadow: inset 4px 0 0 0 $color-navitem-six;

  & span {
    color: $color-navitem-six;
  }
}

.module-nav--main .module-nav__lvl2 > .module-nav__item > ul {
  display: none;
}

// --------------------------------------------------------------------------
// C. Main - More
// --------------------------------------------------------------------------

.module-nav--more {
  position: absolute;
  display: block;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  left: 0;
  top: 100%;
  transform-origin: 90% 0;
}

.module-nav--more.-open {
  opacity: 1;
  max-height: initial;
  width: 100vw;
  overflow: visible;
  animation: fadeIn .2s;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba($black, 0.5);
    height: 10000vh;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    height: 100%;
    z-index: 1;
  }
}

.module-nav--more.-open.-fade-out {
  opacity: 0;
  animation: fadeOut .2s;
}

.module-nav--more.-open.-close {
  animation: slideOutUp .5s;
}

.module-nav--more .module-search {
  position: relative;
  top: 0;
  transform: translate3d(0,0,0);
  width: 100vw;
  background-color: rgba($color-alpha, .05);
  box-shadow: inset 0 -1px 0 0 rgba($color-alpha, .25);
  z-index: 2;
}

.module-nav--more .module-search .input__field--input {
  position: relative;
}

.module-nav--more .module-search .input__field--input > button {
  background-color: transparent;
}

.module-nav--more .module-search .input__field--input > input {
  width: 100%;
}

.module-nav--more .module-search .input__field--input > label {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba($color-text-alpha, .65);
}

.module-nav--more .module-nav__lvl1 {
  position: relative;
  background-color: $white;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
  padding: 1.354em 0;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item {
  opacity: 0;
  transform: translateX(-1em);
  transition-property: opacity , transform;
  transition-duration: .3s;
  transition-timing-function: $easing-alpha-in-out;
}

.module-nav--more.-open .module-nav__lvl1 > .module-nav__item {
  opacity: 1;
  transform: translateX(0);
  transition-delay: .1s;
}

.module-nav--more.-open .module-nav__lvl1 > .module-nav__item:nth-child(1) {
  transition-delay: .17s;
}
.module-nav--more.-open .module-nav__lvl1 > .module-nav__item:nth-child(2) {
  transition-delay: .24s;
}
.module-nav--more.-open .module-nav__lvl1 > .module-nav__item:nth-child(3) {
  transition-delay: .31s;
}
.module-nav--more.-open .module-nav__lvl1 > .module-nav__item:nth-child(4) {
  transition-delay: .38s;
}
.module-nav--more.-open .module-nav__lvl1 > .module-nav__item:nth-child(5) {
  transition-delay: .45s;
}
.module-nav--more.-open .module-nav__lvl1 > .module-nav__item:nth-child(6) {
  transition-delay: .52s;
}
.module-nav--more.-open .module-nav__lvl1 > .module-nav__item:nth-child(7) {
  transition-delay: .59s;
}
.module-nav--more.-open .module-nav__lvl1 > .module-nav__item:nth-child(8) {
  transition-delay: .66s;
}
.module-nav--more.-open .module-nav__lvl1 > .module-nav__item:nth-child(9) {
  transition-delay: .73s;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item > a {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.72em 1em;
  color: $color-alpha;
  cursor: pointer;

  & span {
    display: block;
    @include ms-font-size(-1);
    @include font-weight-body(medium);
    color: $color-alpha;
  }
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.module-nav__item--1 > a {
  color: $color-navitem-one;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.module-nav__item--2 > a {
  color: $color-navitem-two;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.module-nav__item--3 > a {
  color: $color-navitem-three;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.module-nav__item--4 > a {
  color: $color-navitem-four;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.module-nav__item--5 > a {
  color: $color-navitem-five;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.module-nav__item--6 > a {
  color: $color-navitem-six;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.module-nav__item--1 > a span {
  color: $color-navitem-one;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.module-nav__item--2 > a span {
  color: $color-navitem-two;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.module-nav__item--3 > a span {
  color: $color-navitem-three;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.module-nav__item--4 > a span {
  color: $color-navitem-four;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.module-nav__item--5 > a span {
  color: $color-navitem-five;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.module-nav__item--6 > a span {
  color: $color-navitem-six;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.module-nav__item--hasSubpages > a:after {
  @include icomoon("\e906");
  transform: rotate(0deg);
  transform-origin: 50% 50%;
  opacity: 1;
  transition: opacity .2s ease-out, transform .4s ease-out;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.-opened > a:after {
  transform: rotate(90deg);
  transform-origin: 50% 50%;
  transition: transform .4s ease-out;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item > ul {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 1s, max-height 1s;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.-opened > ul {
  opacity: 1;
  max-height: none;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item .module-nav__lvl2 > .module-nav__item {
  opacity: 0;
  transform: translateX(-1em);
  transition-property: opacity , transform;
  transition-duration: .3s;
  transition-timing-function: $easing-alpha-in-out;
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 0s;

  &:first-child {
    margin-top: 0.22em;
  }

  &:last-child {
    margin-bottom: 0.22em;
  }
}

.module-nav--more .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(1) {
  transition-delay: .07s;
}
.module-nav--more .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(2) {
  transition-delay: .14s;
}
.module-nav--more .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(3) {
  transition-delay: .21s;
}
.module-nav--more .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(4) {
  transition-delay: .28s;
}
.module-nav--more .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(5) {
  transition-delay: .35s;
}
.module-nav--more .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(6) {
  transition-delay: .42s;
}
.module-nav--more .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(7) {
  transition-delay: .49s;
}
.module-nav--more .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(8) {
  transition-delay: .56s;
}
.module-nav--more .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 > .module-nav__item:nth-child(9) {
  transition-delay: .63s;
}

.module-nav--more .module-nav__lvl2 > .module-nav__item > a {
  display: block;
  padding: 0.5em 1em;
  border: 0 none;
  background-color: transparent;
  box-shadow: none;

  & span {
    display: block;
    @include ms-font-size(-1);
    @include font-weight-body(medium);
    color: rgba($color-text-alpha, .65);
  }

  &:hover span {
    color: $color-alpha;
  }
}

.module-nav--more .module-nav__item.module-nav__item--1 > .module-nav__lvl2 > .module-nav__item > a:hover span {
  color: $color-navitem-one;
}

.module-nav--more .module-nav__item.module-nav__item--2 > .module-nav__lvl2 > .module-nav__item > a:hover span {
  color: $color-navitem-two;
}

.module-nav--more .module-nav__item.module-nav__item--3 > .module-nav__lvl2 > .module-nav__item > a:hover span {
  color: $color-navitem-three;
}

.module-nav--more .module-nav__item.module-nav__item--4 > .module-nav__lvl2 > .module-nav__item > a:hover span {
  color: $color-navitem-four;
}

.module-nav--more .module-nav__item.module-nav__item--5 > .module-nav__lvl2 > .module-nav__item > a:hover span {
  color: $color-navitem-five;
}

.module-nav--more .module-nav__item.module-nav__item--6 > .module-nav__lvl2 > .module-nav__item > a:hover span {
  color: $color-navitem-six;
}

.module-nav--more .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a {
  box-shadow: inset 4px 0 0 0 $color-alpha;

  & span {
    color: $color-alpha;
  }
}

.module-nav--more .module-nav__item.module-nav__item--1 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-one;

  & span {
    color: $color-navitem-one;
  }
}

.module-nav--more .module-nav__item.module-nav__item--2 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-two;

  & span {
    color: $color-navitem-two;
  }
}

.module-nav--more .module-nav__item.module-nav__item--3 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-three;

  & span {
    color: $color-navitem-three;
  }
}

.module-nav--more .module-nav__item.module-nav__item--4 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-four;

  & span {
    color: $color-navitem-four;
  }
}

.module-nav--more .module-nav__item.module-nav__item--5 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-five;

  & span {
    color: $color-navitem-five;
  }
}

.module-nav--more .module-nav__item.module-nav__item--6 > .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a {
  box-shadow: inset 4px 0 0 0 $color-navitem-six;

  & span {
    color: $color-navitem-six;
  }
}

.module-nav--more .module-nav__lvl2 > .module-nav__item > ul {
  display: none;
}

.module-nav--more .input__field--input input{
  background: transparent;
  border: none;
  box-shadow: none;
  margin: 0;
  @include ms-padding(0 0 0 zero);
  line-height: 1.8;
  @include font-weight-body(medium);
  color: $color-text-alpha;
}

.module-nav--more .input__field--input a{
  position: absolute;
  top: 0;
  right: 0;
  width: calc(2em + 1.8em);
  height: 100%;
}

.module-nav--more .input__field--input .submit{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &:before {
    color: $color-alpha;
  }
}

.more-button {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 100;
  padding-left: 19px;

  & .more-text {
    display: inline-block;

    & span {
      display: block;
      @include ms-font-size(-1);
      @include font-weight-body(medium);
      color: $color-alpha;
    }
  }
}

.-nav-mobile .more-button {
  width: 40px;
  height: 40px;
  right: calc(2% - 10px);
  justify-content: center;
  padding-left: 18px;

  @include breakpoint(break-1) {
    right: -10px;
  }
}

.more-button .more-icon {
  position: absolute;
  width: 15px;
  height: 15px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
}

.more-button .more-icon .bubble {
  position: absolute;
  width: 3px;
  height: 3px;
  left: 6px;
  top: 6px;
  border-radius: 10px;
  background-color: $color-alpha;
}

.more-button .more-icon .bubble:first-child {
  transform: translateY(-6px);
  animation: .35s $easing-alpha-out 0s 1 forwards more-button-dots-first-out;
}

.more-button .more-icon .bubble:nth-child(2) {
  animation: .35s $easing-alpha-out 0s 1 forwards more-button-dots-second-out;
}

.more-button .more-icon .bubble:last-child {
  transform: translateY(6px);
  animation: .35s $easing-alpha-out 0s 1 forwards more-button-dots-last-out;
}

.more-button.-open .more-icon .bubble {
  transform: translateY(0);
}

.more-button.-open .more-icon .bubble:first-child {
  animation: .35s $easing-alpha-out 0s 1 forwards more-button-dots-first-in;
}

.more-button.-open .more-icon .bubble:nth-child(2) {
  animation: .35s $easing-alpha-out 0s 1 forwards more-button-dots-second-in;
}

.more-button.-open .more-icon .bubble:last-child {
  animation: .35s $easing-alpha-out 0s 1 forwards more-button-dots-last-in;
}

@keyframes more-button-dots-first-in {
  0% { width: 3px; height: 3px; transform: translateY(-6px) rotate(0deg); }
  50% { width: 3px; height: 3px; transform: translateY(0) rotate(0deg); }
  100% { width: 100%; height: 2px; transform: translateX(-7.5px) translateY(0) rotate(45deg); }
}

@keyframes more-button-dots-first-out {
  0% { width: 100%; height: 2px; transform: translateX(-7.5px) translateY(0) rotate(45deg); }
  50% { width: 3px; height: 3px; transform: translateY(0) rotate(0deg); }
  100% { width: 3px; height: 3px; transform: translateY(-6px) rotate(0deg); }
}

@keyframes more-button-dots-second-in {
  0% { width: 3px; height: 3px; }
  50% { width: 3px; height: 3px; }
  100% { width: 2px; height: 2px; }
}

@keyframes more-button-dots-second-out {
  0% { width: 2px; height: 2px; }
  50% { width: 3px; height: 3px; }
  100% { width: 3px; height: 3px; }
}

@keyframes more-button-dots-last-in {
  0% { width: 3px; height: 3px; transform: translateY(6px) rotate(0deg); }
  50% { width: 3px; height: 3px; transform: translateY(0) rotate(0deg); }
  100% { width: 100%; height: 2px; transform: translateX(-7.5px) translateY(0) rotate(-45deg); }
}

@keyframes more-button-dots-last-out {
  0% { width: 100%; height: 2px; transform: translateX(-7.5px) translateY(0) rotate(-45deg); }
  50% { width: 3px; height: 3px; transform: translateY(0) rotate(0deg); }
  100% { width: 3px; height: 3px; transform: translateY(6px) rotate(0deg); }
}

.more-button .menu-icon {
  position: relative;
  width: 22px;
  height: 14px;
  -webkit-backface-visibility: hidden;
}

.more-button .menu-icon .bubble {
  position: absolute;
  width: 22px;
  height: 2px;
  left: 0px;
  top: 6px;
  border-radius: 0;
  background-color: $color-alpha;
}

.more-button .menu-icon .bubble:first-child {
  transform: translateY(-8px);
  animation: .35s $easing-alpha-out 0s 1 forwards mobile-button-dots-first-out;
}

.more-button .menu-icon .bubble:nth-child(2) {
  transform: translateY(0);
  animation: .35s $easing-alpha-out 0s 1 forwards mobile-button-dots-second-out;
}

.more-button .menu-icon .bubble:last-child {
  transform: translateY(8px);
  animation: .35s $easing-alpha-out 0s 1 forwards mobile-button-dots-last-out;
}

.more-button.-open .menu-icon .bubble {
  transform: translateY(0);
}

.more-button.-open .menu-icon .bubble:first-child {
  animation: .35s $easing-alpha-out 0s 1 forwards mobile-button-dots-first-in;
}

.more-button.-open .menu-icon .bubble:nth-child(2) {
  animation: .35s $easing-alpha-out 0s 1 forwards mobile-button-dots-second-in;
}

.more-button.-open .menu-icon .bubble:last-child {
  animation: .35s $easing-alpha-out 0s 1 forwards mobile-button-dots-last-in;
}

@keyframes mobile-button-dots-first-in {
  0% { width: 22px; height: 2px; transform: translateX(0) translateY(-6px) rotate(0deg); }
  50% { width: 2px; height: 2px; transform: translateX(10px) translateY(0) rotate(0deg); }
  100% { width: 100%; height: 2px; transform: translateX(1.5px) translateY(0) rotate(45deg); }
}

@keyframes mobile-button-dots-first-out {
  0% { width: 100%; height: 2px; transform: translateX(1.5px) translateY(0) rotate(45deg); }
  50% { width: 2px; height: 2px; transform: translateX(10px) translateY(0) rotate(0deg); }
  100% { width: 22px; height: 2px; transform: translateX(0) translateY(-6px) rotate(0deg); }
}

@keyframes mobile-button-dots-second-in {
  0% { width: 22px; height: 2px; transform: translateX(0) translateY(0); }
  50% { width: 2px; height: 2px; transform: translateX(10px) translateY(0); }
  100% { width: 2px; height: 2px; transform: translateX(10px) translateY(0); }
}

@keyframes mobile-button-dots-second-out {
  0% { width: 2px; height: 2px; transform: translateX(10px) translateY(0); }
  50% { width: 2px; height: 2px; transform: translateX(10px) translateY(0); }
  100% { width: 22px; height: 2px; transform: translateX(0) translateY(0); }
}

@keyframes mobile-button-dots-last-in {
  0% { width: 22px; height: 2px; transform: translateX(0) translateY(6px) rotate(0deg); }
  50% { width: 2px; height: 2px; transform: translateX(10px) translateY(0) rotate(0deg); }
  100% { width: 100%; height: 2px; transform: translateX(1.5px) translateY(0) rotate(-45deg); }
}

@keyframes mobile-button-dots-last-out {
  0% { width: 100%; height: 2px; transform: translateX(1.5px) translateY(0) rotate(-45deg); }
  50% { width: 2px; height: 2px; transform: translateX(10px) translateY(0) rotate(0deg); }
  100% { width: 22px; height: 2px; transform: translateX(0) translateY(6px) rotate(0deg); }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(1, 1, 1);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(1, 1, 1);
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

// --------------------------------------------------------------------------
// 03. Meta
// --------------------------------------------------------------------------

.module-nav.module-nav--meta {
  display: flex;
  align-items: center;
}

.module-nav.module-nav--meta ul.nav {
  display: flex;
  flex-direction: row;
}

.module-nav.module-nav--meta ul.nav > li.module-nav__item {
  @include ms-margin-right(4);

  &:last-child {
    @include ms-margin-right(zero);
  }
}

.module-nav.module-nav--lang .available__lang > a {
  display: block;
  color: $color-alpha;
  @include ms-font-size(-2);

  &:hover {
    color: rgba($color-alpha, .65);
  }
}

.module-nav.module-nav--meta ul.nav > li.module-nav__item > a {
  display: block;
  color: $color-alpha;
  @include ms-font-size(-2);

  &:hover {
    color: rgba($color-alpha, .65);
  }
}

.module-nav--more .module-nav.module-nav--meta {
  position: relative;
  z-index: 2;
  padding-top: 1.228em;
  padding-bottom: 1.228em;
  box-shadow: inset 0 1px 0 0 rgba($color-alpha, .25);
}

.module-nav--more .module-nav.module-nav--meta ul.nav {
  flex-direction: column;
}

.module-nav--more .module-nav.module-nav--meta ul.nav > li.module-nav__item {
  @include ms-margin-right(zero);
  padding: 0.5em 0;
}

.module-nav--more .module-nav.module-nav--meta ul.nav > li.module-nav__item > a {
  display: block;
  color: $color-alpha;
  @include ms-font-size(-2);

  &:hover {
    color: rgba($color-alpha, .65);
  }
}

// --------------------------------------------------------------------------
// 04. Footer
// --------------------------------------------------------------------------

.module-nav.module-nav--footer .nav__headline {
  @include ms-margin-bottom(0);
  cursor: pointer;

  @include breakpoint(break-1) {
    cursor: auto;
  }
}

.module-nav.module-nav--footer .nav__headline > span.heading.gamma {
  position: relative;
  display: block;
  color: $white;

  &:after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform .35s $easing-alpha-out;
  }

  @include breakpoint(break-1) {
    &:after {
      display: none;
    }
  }
}

.module-nav.module-nav--footer ul.nav {
  border-bottom: 1px solid rgba($white, .25);
  max-height: 0;
  @include ms-padding-bottom(zero);
  overflow: hidden;
  transition: max-height .35s $easing-alpha-out, padding-bottom .35s $easing-alpha-out;

  @include breakpoint(break-1) {
    border: 0 none;
    max-height: none;
    padding: 0;
  }
}

.col-four .module-nav.module-nav--footer ul.nav {
  border-bottom: none;
}

.module-nav.module-nav--footer.-open {
  & .nav__headline > span.heading.gamma:after {
    transform: translateY(-50%) rotate(90deg);
  }

  & ul.nav {
    max-height: 250px;
    @include ms-padding-bottom(0);
  }

  @include breakpoint(break-1) {
    & ul.nav {
      max-height: none;
      padding: 0;
    }
  }
}

.module-nav.module-nav--footer ul.nav > li.module-nav__item {
  @include ms-margin-bottom(-3);
  @include ms-padding-left(-3, break-0);
  @include ms-padding-left(zero, break-1 to break-4);

  &:last-child {
    @include ms-margin-bottom(zero);
  }
}

.module-nav.module-nav--footer ul.nav > li.module-nav__item > a {
  color: rgba($white, .75);

  &:hover {
    color: $white;
  }
}



// --------------------------------------------------------------------------
// 05. Legal
// --------------------------------------------------------------------------

.module-nav.module-nav--legal ul.nav {
  display: flex;
  flex-direction: row;
}

.module-nav.module-nav--legal ul.nav > li.module-nav__item {
  @include ms-margin-right(4);

  &:last-child {
    @include ms-margin-right(zero);
  }
}

.module-nav.module-nav--legal ul.nav > li.module-nav__item > a {
  color: $white;

  &:hover {
    color: rgba($white, .75);
  }
}

// --------------------------------------------------------------------------
// 06. Breadcrumb
// --------------------------------------------------------------------------

.module-nav--breadcrumb {
  position: relative;
}

.module-nav--breadcrumb .nav__crumb {
  display: inline-block;
  position: relative;
  @include ms-margin-right(0);

  &.nav__crumb--hidden,
  &:last-child:after {
    display: none;
  }

  & .crumb__title {
    display: inline-block;
    @include ms-margin-right(0);
  }

  &:after {
    @include icomoon("\e901");
    display: inline-block;
    @include ms-font-size(-1);
    line-height: inherit;
  }
}

.module-nav--breadcrumb .nav__crumb a {
  display: inline-block;
  color: $color-beta;
  @include ms-font-size(-1);
  transition: opacity .25s $easing-alpha-out;

  &:visited,
  &:active,
  &:focus,
  &:hover {
    color: $color-beta;
  }

  &:hover {
    opacity: .5;
  }
}

.module-nav--breadcrumb .nav__crumb span:not(.crumb__title) {
  display: inline-block;
  @include ms-font-size(-1);
}

.module-nav--breadcrumb .nav__crumb--first a,
.module-nav--breadcrumb .nav__crumb--first span:not(.crumb__title) {
  display: inline-block;
  color: $color-beta;
  @include font-weight-body(medium);
}
